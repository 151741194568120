@import '../../../scss/helpers/mixins';

.content {
  max-width: 1004px;
  margin: 0 auto;
  padding: 130px 0 120px;

  h2 {
    padding-bottom: 64px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    color: #000;

    .mobile-bk {
      display: none;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
    column-gap: 16px;
  }

  .grid-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 64px;
    column-gap: 8px;
  }

  .table-title {
    padding-bottom: 16px;
    font-size: 23px;
    color: #000;
    font-weight: 700;
  }

  .deduct-policy {
    display: flex;
    font-size: 14px;

    .deduct-title {
      flex: 0.55;
      padding-left: 16px;
      line-height: 130%;
      white-space: nowrap;
    }

    .deduct-policy-list {
      flex: 4;
      padding-left: 16px;

      .mobile-bk {
        display: none;
      }

      li {
        display: flex;
        align-items: flex-start;
        color: #4c4c4c;
        line-height: 130%;
        padding-bottom: 7px;

        > span {
          flex: 1;
        }

        span.bold {
          font-weight: 700;
        }

        i {
          display: inline-block;
          width: 11px;
          height: 7px;
          margin-top: 6px;
          background: url('../../../images/i_black_chk.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 8px;
        }
      }
    }
  }
}

@include tablet1200 {
  .content {
    max-width: 100.4rem;
    padding: 13rem 0 12rem;

    h2 {
      padding-bottom: 6.4rem;
      font-size: 3.6rem;
      line-height: 4.8rem;
    }

    .flex-box {
      padding-bottom: 10rem;
      column-gap: 1.6rem;
    }

    .grid-box {
      padding-bottom: 6.4rem;
      column-gap: 0.8rem;
    }

    .table-title {
      padding-bottom: 1.6rem;
      font-size: 2.3rem;
    }

    .deduct-policy {
      font-size: 1.4rem;

      .deduct-title {
        padding-left: 1.6rem;
      }

      .deduct-policy-list {
        padding-left: 1.6rem;

        li {
          padding-bottom: 0.7rem;

          i {
            width: 1.1rem;
            height: 0.7rem;
            margin-top: 0.6rem;
            margin-right: 0.8rem;
          }
        }
      }
    }
  }
}

@include tablet1111 {
  .content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@include tablet1000 {
  .content {
    .grid-box {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 0.8rem;
    }
  }
}

@include tablet767 {
  .content {
    padding-top: 13.8rem;

    h2 {
      font-size: 2.8rem;

      .mobile-bk {
        display: block;
      }

      .pc-bk {
        display: none;
      }
    }

    .flex-box {
      flex-direction: column;
      row-gap: 1.6rem;
    }

    .deduct-policy {
      flex-direction: column;
      row-gap: 1.2rem;

      .deduct-title {
        padding-left: 0;
      }

      .deduct-policy-list {
        padding-left: 0;

        .mobile-bk {
          display: block;
        }
      }
    }
  }
}


@include tablet600 {
  .content {
    .grid-box {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 0.8rem;
    }
  }
}

@include mobile417 {
  .content {
    h2 {
      font-size: 2.4rem;
      line-height: 160%;
    }

    .deduct-policy {
      font-size: 1.1rem;

      .deduct-policy-list {


        li {
          i {
            width: 0.7rem;
            height: 0.5rem;
            margin-top: 0.4rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}