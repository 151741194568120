@import '../../../../scss/helpers/mixins';

.footer {
  width: 100%;
  background-color: #B1C6FF;

  .footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1000px;
    padding: 120px 0 101px;
    margin: 0 auto;

    .left {
      color: #333;

      .company {
        padding-bottom: 20px;
        font-weight: 600;
        font-size: 18px;
      }

      .address {
        padding-bottom: 40px;
        line-height: 24px;
        font-size: 15px;
      }

      .policy {
        display: inline-block;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 500;

        &.wall::before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 15px;
          margin-bottom: -1px;
          margin-right: 20px;
          background-color: #333;
        }
      }
    }

    .right {
      padding-top: 50px;

      a {
        display: inline-block;
        height: 36px;
        margin-right: 12px;

        img {
          height: 100%;
        }

        //&:nth-child(2) {
        //
        //  &::after {
        //    content: '';
        //    display: inline-block;
        //    width: 1px;
        //    height: 16px;
        //    margin-bottom: 8px;
        //    margin-left: 12px;
        //    background-color: #5c67ff;
        //  }
        //}
      }
    }
  }
}

@include tablet1111 {
  .footer {
    width: calc(100% - 6.0rem);
    padding: 0 3.0rem;

    .footer-inner {
      width: 100%;
    }
  }
}


@include tablet767 {
  .footer {
    width: calc(100% - 8.0rem);
    padding: 0 4.0rem;

    .footer-inner {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

@include mobile500 {
  .footer {

    .footer-inner {
      padding: 5.6rem 0;

      .left {
        .company {
          padding-bottom: 1rem;
          font-size: 1.5rem;
          line-height: 2.3rem;
        }

        .address {
          padding-bottom: 2.4rem;
          line-height: 2rem;
          font-size: 1.3rem;
        }

        .policy {
          font-size: 1.3rem;

          &.wall::before {
            height: 1.0rem;
          }
        }
      }

      .right {
        padding-top: 4.9rem;

        a {
          height: 3.2rem;
          margin-right: 1.1rem;
        }
      }
    }
  }
}