@import '../../../scss/helpers/mixins';
@import '../../../scss/themes/colors.module';

.edit-financial-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 80.5rem;
  height: calc(100% - 5.0rem);
  padding: 2.5rem 4.7rem;
  border-left: .1rem solid #eee;
  background-color: #fff;
  border-bottom-right-radius: .8rem;
  border-top-right-radius: .8rem;
  font-weight: 400;

  .btn-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.0rem;
    padding-bottom: 1.5rem;

    button {
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        margin-left: 1.2rem;
        box-sizing: border-box;
        border-radius: .6rem;
        font-size: 1.3rem;
        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }

      &.back {
        padding: .8rem 1.0rem;
        border-radius: .8rem;
        font-size: 1.4rem;

        &:active {
          background-color: #f9f9f9;
        }

        i {
          width: .8rem;
          height: 1.5rem;
          margin-right: 1.4rem;
          background-image: url('../../../images/back_btn.png');
          vertical-align: -10%;
        }
      }
    }
  }

  .big-title {
    padding-bottom: 1.1rem;
    margin-bottom: 1.1rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;

    button.reset-btn {
      font-size: 1.2rem;
      color: #808080;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../../images/reset.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 1.0rem;
        margin-right: .3rem;
        vertical-align: -10%;
      }
    }
  }

  .scroll-box {
    width: 100%;
    height: calc(100% - 5.8rem);

    .scroll-area {
      width: 100%;
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-x: hidden;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }

  .select-line {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .tab-area {
      display: flex;
      column-gap: 1.5rem;
      padding-bottom: 3.7rem;

      button {
        padding: 1.5rem;
        border-bottom: .3rem solid #fff;
        font-size: 1.5rem;
        font-weight: 500;
        color: #999;

        &.on {
          border-bottom: .3rem solid $color-main;
          color: #000;
        }
      }
    }

    .button-area {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 1rem;
      padding-top: 1rem;

      .select-box {
        height: auto;
        padding: 1.0rem 1.5rem 1.0rem 1.5rem;
        background-color: #f9f9f9;
        border-radius: .8rem;

        .input-box {
          font-size: 1.4rem;
          font-weight: 700;
        }
      }

      .title {
        padding-right: 1rem;
        font-size: 1.5rem;
      }

      //.financial-state-upload {
      //  @include skyblue-btn(upload_color);
      //
      //  font-size: 1.3rem;
      //
      //  i {
      //    width: 1.5rem;
      //    height: 1.5rem;
      //    vertical-align: -10%;
      //  }
      //}
    }
  }
}

.finance-edit-table {
  width: 100%;
  box-sizing: border-box;

  .title-area {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.8rem;

    .title {
      font-size: 1.5rem;
    }

    .unit {
      font-size: 1.3rem;
      color: #808080;
    }
  }

  .table-wrap {
    border-radius: .8rem;
    border: 0.05rem solid #ccc;

    .head-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-end;
      text-align: center;
      width: 100%;
      height: 4.1rem;
      font-size: 1.4rem;
      background-color: #999;
      color: #fff;
      border-top-left-radius: .8rem;
      border-top-right-radius: .8rem;
      font-weight: 700;

      .head {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 4.1rem;

        &:not(:first-child) {
          text-align: right;
          padding-right: 1.8rem;
          justify-content: flex-end;
        }

        &:not(:last-child) {
          border-right: 0.05rem solid #ccc;
        }

        .upload-btn {
          position: relative;
          width: 3.1rem;
          height: 3.1rem;
          background-image: url('../../../images/upload_wh.png');
          background-repeat: no-repeat;
          background-size: 46% 48%;
          background-position: center 40%;
          margin-left: 0.6rem;
          border-radius: 50%;
          vertical-align: -53%;
        }
      }

      //.head:not(:last-child) {
      //  height: 100%;
      //  line-height: 4.1rem;
      //  border-right: 0.05rem solid #ccc;
      //}
    }

    .body-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      text-align: center;
      width: 100%;
      height: 3.6rem;
      border-bottom: 0.05rem solid #ccc;
      font-size: 1.3rem;

      &:last-child {
        border-bottom: none;
      }

      .body {
        height: 100%;
        line-height: 3.6rem;
        border-right: 0.05rem solid #ccc;

        &:first-child {
          text-align: left;
          text-indent: 2.5rem;
        }

        &:last-child {
          border-right: none;
        }

        input {
          width: calc(100% - 3.5rem);
          margin: .6rem;
          padding-right: 1.8rem;
          border-radius: .4rem;
          text-align: right;
          border: none;
          outline: none;
          background-color: transparent;
          letter-spacing: 0rem;

          &:focus {
            background-color: #eee;
          }
        }
      }

      //재무상태표 depth
      &.dep1_1,
      &.dep2_1 {
        background-color: #e0e0e0;
        font-weight: 700;

        input:focus {
          background-color: #fff;
        }
      }

      &.dep1_2,
      &.dep2_2 {
        background-color: #f2f2f2;

        li:first-child {
          font-weight: 500;
        }

        input:focus {
          background-color: #fff;
        }
      }

      &.dep1_3 {

        li:first-child {
          font-weight: 500;
        }
      }

      &.dep2_3 {

        li:first-child {
          font-weight: 400;
        }
      }

      &.dep1_4 li:first-child,
      &.dep2_4 li:first-child {
        text-indent: 3.3rem;
      }

      &.dep1_5 li:first-child,
      &.dep2_5 li:first-child {
        text-indent: 4.1rem;
      }
    }
  }
}

@media (hover: hover) {
  .back:hover {
    background-color: #f9f9f9;
  }

  .finance-edit-table {
    .table-wrap {
      .head-box {
        .head {
          .upload-btn {
            &:hover {
              background-color: #808080;
              vertical-align: 28%;

              > p {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}