.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99.9%;
  flex-wrap: nowrap;
  padding-top: 5.0rem;
  padding-bottom: .5rem;
  margin-bottom: 2.7rem;
  border-bottom: .1rem solid #eee;
  font-size: 2.0rem;
  font-weight: 700;

  p.title, div.btn {
    display: flex;
    align-items: center;
    height: 4.9rem;
  }
}

.desc-line {
  padding-bottom: 1.2rem;
  font-size: 1.3rem;
  color: #808080;

  &.desc2 {
    margin-bottom: 2.7rem;
  }
}