@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module.scss';

.review-detail {

  .visual {
    display: flex;
    align-items: flex-end;
    padding: 130px 0 64px;
    background-color: #fff;

    div {
      width: 50%;
    }

    .left-area {
      text-align: left;
      padding-left: 50px;

      .title {
        padding-bottom: 16px;
        font-size: 36px;
        font-weight: 700;
        line-height: 48px;
      }

      .sub-title {
        font-size: 16px;
        line-height: 26px;
      }
    }

    .right-area {
      text-align: right;

      .write-review-btn {
        @include primary-btn-design(12px 16px);

        display: inline-block;
        line-height: 26px;
        font-size: 18px;
        cursor: pointer;

        span {
          padding-right: 3px;
          font-weight: 700;
        }
      }
    }
  }

  .section2 {
    padding: 80px 0 96px;
    background-color: #e1edff;

    .grid-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 24px;
      align-items: flex-start;

      .grid-column-box {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
      }
    }
  }

  .mobile-bk {
    display: none;
  }
}

@include tablet767() {
  .review-detail {

    .visual {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 12.4rem 0 6.4rem;
      background-color: #fff;

      div {
        width: 100%;
      }

      .left-area {
        text-align: center;
        padding-left: 0;

        //.title {
        //  padding-bottom: 16px;
        //  font-size: 36px;
        //  font-weight: 700;
        //  line-height: 48px;
        //}
        //
        //.sub-title {
        //  font-size: 16px;
        //  line-height: 26px;
        //}
      }

      .right-area {
        text-align: center;

        .write-review-btn {
          margin-top: 3.6rem;
        }
      }
    }

    .section2 {
      .grid-box {
        grid-template-columns: 1fr;
      }
    }
  }
}

@include mobile500() {
  .review-detail {

    .section2 {
      padding: 7.2rem 2.4rem 8.8rem;
    }
  }
}

@include mobile417() {
  .review-detail {
    .visual {
      .left-area {
        .title {
          padding-bottom: 3.6rem;
          font-size: 2.4rem;
          line-height: 3.6rem;
        }

        .sub-title {
          font-size: 1.5rem;
          line-height: 2.2rem;
        }
      }
    }

    .pc-bk {
      display: none;
    }

    .mobile-bk {
      display: block;
    }
  }
}