@import '../../../scss/helpers/mixins';
@import '../../../scss/themes/colors.module';

$padding: 4.7rem;
$scroll-pos: calc($padding - .8rem); //스크롤바가 우측에서 .8rem 떨어지도록

.edit-info {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(80.5rem + $scroll-pos);
  height: calc(100% - 5.0rem);
  padding: 2.5rem calc($padding - $scroll-pos) 2.5rem $padding;
  border-left: .1rem solid #eee;
  background-color: #fff;
  border-bottom-right-radius: .8rem;
  border-top-right-radius: .8rem;

  form {
    height: 100%;
  }

  .button-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: $scroll-pos;
    height: 4.0rem;
    padding-bottom: 1.5rem;

    .back {
      padding: .8rem 1.0rem;
      border-radius: .8rem;
      font-size: 1.4rem;

      &:active {
        background-color: #f9f9f9;
      }

      i {
        display: inline-block;
        width: .8rem;
        height: 1.5rem;
        margin-right: 1.4rem;
        background: url('../../../images/back_btn.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -10%;
      }
    }

    .save {
      @include primary-btn-design(1.0rem 1.9rem);

      font-size: 1.3rem;
      font-weight: 700;
      color: #fff;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: .4rem;
        background: url('../../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -13%;
      }
    }

    .add-btn {
      @include skyblue-btn(plus);

      margin-right: 1.2rem;
    }
  }

  .input-area {
    height: calc(100% - 4.0rem);;
    overflow: hidden;
    overflow-y: auto;
    padding-right: $scroll-pos;

    .scroll {
      padding-top: 4.0rem;
      padding-bottom: 2.6rem;
    }

    .title {
      font-size: 1.7rem;
      font-weight: 700;
      padding-bottom: 1.6rem;
    }

    .shareholder-box {
      position: relative;
      padding-top: 5.7rem;

      .line {
        display: grid;
        grid-template-columns: 1fr 1.2fr 1.05fr;
        column-gap: 3.5rem;
        padding-right: 3.4rem;
        padding-bottom: 2.0rem;
      }

      .delete-btn {
        position: absolute;
        right: 0;
        top: 5.6rem;
        width: 2.5rem;
        height: 4.6rem;
        background: url('../../../images/delete.png') no-repeat;
        background-size: 55% 30%;
        background-position: center right;
      }

      .check-box-area {
        display: flex;
        justify-content: flex-end;
        padding-right: 3.5rem;
        font-size: 1.3rem;
        line-height: 1.9rem;

        &.add-trust-input {
          justify-content: space-between;
        }

        .calendar-box {
          width: 21.6rem;
        }

        .check-box {
          @include green-chkbox();

          width: 7.0rem;
          text-align: right;

          input {
            float: left;
          }
        }
      }
    }
  }

  .total-box {
    display: grid;
    grid-template-columns: 21.5rem 25.8rem 22.8rem;
    row-gap: 2.0rem;
    column-gap: 3.5rem;
  }
}

@media (hover: hover) {
  .back:hover {
    background-color: #f9f9f9;
  }
}