@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module.scss';

.business-type-pop {
  width: 81rem;
  height: 69.1rem;

  &.innerHVer2 {
    height: 100%;
  }

  form {
    height: 100%;

  }

  .title {
    padding: 1.5rem 1.7rem;
    font-size: 1.3rem;
    font-weight: 700;
    border-bottom: .1rem solid #eee;
  }

  .type-list {
    max-height: 56.2rem;
    height: calc(100% - 12.6rem);

    .scroll {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: transparent #d9d9d9;

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: .2rem;
      }
    }

    .gray-txt {
      color: #808080;
    }
    
    .type-box {
      display: flex;
      width: 100%;
      height: 100%;
      background-color: #f9f9f9;

      .field-box {
        width: 27.0rem;
        height: 100%;
        box-sizing: border-box;
        border-right: .1rem solid #eee;
        background-color: #fff;

        .field {
          position: relative;
          width: 100%;
          height: 3.6rem;
          font-size: 1.3rem;

          .tooltip {
            display: none;
            position: absolute;
            left: .5rem;
            top: -3.0rem;
            width: auto;
            white-space: nowrap;
            padding: .8rem 1.3rem;
            border-radius: .8rem;
            font-size: 1.0rem;
            background-color: #eee;
            color: #000;
            box-shadow: 0 0 0 rgba(0,0,0,0.3);

            &::after {
              position: absolute;
              left: 1.0rem;
              bottom: -1.0rem;
              font-size: 1.2rem;
              content: '▼';
              color: #eee;
            }
          }

          &:active {
            background-color: #f9f9f9;

            .tooltip {
              display: block;
            }
          }

          label {
            display: inline-block;
            width: calc(100% - 3.4rem);
            height: 100%;
            padding: 0 1.7rem;
            line-height: 3.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }

          input[type='radio'] {
            display: none;
          }

          input[type='radio']:checked + label{
            color: $color-main;
            font-weight: 700;
            background-color: #f8f8ff;
          }
        }
      }

      .info-line {
        padding: 1.3rem 1.7rem;
        font-size: 1.2rem;
        color: #333;

        span {
          font-weight: 700;
          padding-right: .3rem;
        }
      }
    }
  }

  .btn-area {
    display: flex;
    justify-content: flex-end;
    padding: 2.0rem 1.7rem;
    column-gap: .8rem;

    .close-btn {
      @include text-btn-design(1.0rem 2.0rem);
    }

    .save-btn {
      @include primary-btn-design(1.0rem 1.9rem);

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        margin-right: .6rem;
        vertical-align: -10%;
      }
    }
  }
}

@media (hover: hover) {
  .field:hover {
    background-color: #f9f9f9;
  }
}