@import "../../scss/helpers/mixins";

.product-box {
  padding: 2.4rem;
  border: .1rem solid #eee;
  border-radius: .8rem;
  box-sizing: border-box;
  cursor: pointer;

  .sub-title {
    padding-bottom: 0.3rem;
    line-height: 1.9rem;
    font-size: 1.2rem;
    color: #5c67ff;
  }

  .title {
    padding-bottom: 0.6rem;
    line-height: 2.8rem;
    font-size: 2rem;
    font-weight: 700;
    color: #5c67ff;
  }

  .one-time-ss {
    color: #666;
  }

  .desc {
    padding-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #808080;

    span {
      font-weight: 700;
      color: #000;
    }
  }

  .price-info {
    display: flex;
    column-gap: 1.6rem;
    padding-bottom: 1.2rem;
    line-height: 2.4rem;
    font-weight: 700;

    .credit {
      font-size: 1.6rem;

      .month {
        font-weight: 400;
      }
    }

    .price {
      font-size: 1.7rem;
    }
  }

  .price-per-case {
    padding: 1.2rem 0;
    border-top: 1px solid #eee;
    font-size: 1.3rem;
    line-height: 2rem;
    color: #4c4c4c;
  }

  .payment-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    line-height: 2rem;
    padding: 1rem 0.8rem;
    border-radius: 0.6rem;
    background-color: #F8F8FF;
    color: #5c67ff;
  }
}

@media (hover: hover) {
  .product-box:hover {
    border: 1px solid #5c67ff;
    background-color: #F8F8FF;

    .payment-btn {
      background-color: #E1EAFB;
    }
  }
}