@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module.scss';

.title-box-right {
  display: flex;
  align-items: center;
  column-gap: 1.3rem;

  .select-box {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    padding: 1.2rem;
    background-color: #f9f9f9;
    border-radius: .6rem;
    font-size: 1.4rem;

    i {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background: url('../../../../images/select_box_icon.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .select-list {

    li {
      padding-right: 1.7rem;

      .update-txt {
        display: inline-block;
        width: 5.7rem;
        height: 2rem;
        margin-left: .9rem;
        line-height: 2rem;
        text-align: center;
        font-size: 1.1rem;
        color: #fff;
        background-color: $color-main;
        border-radius: 5rem;
      }
    }
  }

  .update-info {
    padding: 0.5rem 0.9rem;
    border-radius: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
    white-space: nowrap;
    background-color: #5c67ff;
    color: #fff;
    cursor: pointer;

    @media (hover:hover) {
      &:hover {
        background-color: $color-main-hover;
      }
    }

    &.sky {
      line-height: 1.1rem;
      border-radius: 5rem;
      background-color: $color-back;
      color: $color-main;

      @media (hover:hover) {
        &:hover {
          background-color: $color-back-hover;
        }
      }
    }

    &.gray {
      line-height: 1.1rem;
      border-radius: 5rem;
      background-color: #eee;
      color: #4c4c4c;

      @media (hover:hover) {
        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }

  .select-box-title {
    font-size: 1.4rem;
    font-weight: 400;
  }
} 

.tab-print-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .tab-area {
    display: flex;
    column-gap: 1.5rem;
    padding-bottom: 1.8rem;
    margin-top: -1.5rem;

    button {
      padding: 1.5rem;
      border-bottom: .3rem solid #fff;
      font-size: 1.5rem;
      font-weight: 500;
      color: #999;

      &.on {
        border-bottom: .3rem solid $color-main;
        color: #000;
      }
    }
  }

  .button-area {
    display: flex;
    column-gap: 1.2rem;

    .excel-btn {
      @include gray-border-btn();

      i {
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url('../../../../images/icon_excel.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 0.5rem;
        vertical-align: -5%;
      }
    }

    .summary-report {
      @include gray-border-btn();

      i {
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url('../../../../images/print.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 0.5rem;
        vertical-align: -5%;
      }
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .sub-title {
    line-height: 2.3rem;
    font-size: 1.6rem;
  }
}