@import "../../../scss/helpers/mixins";

.policy-fund-tab {
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.6rem;
    max-height: 500%;
    height: calc(100% - 20rem);
    text-align: center;

    .illust {
      width: 26.5rem;
      height: 13.5rem;
      background: url('../../../images/illust_connect.png') no-repeat;
      background-size: 100% 100%;
    }

    span.color {
      color: $color-main;
    }

    .sub-title {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 500;
    }

    .text {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #666;
    }
  }
}