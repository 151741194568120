@import '../../../../scss/helpers/mixins';

.corporate-regi-copy {
    width: 100%;
    height: 100%;

    .title-area {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 99.9%;
        flex-wrap: nowrap;
        padding-top: 4.0rem;
        padding-bottom: .5rem;
        margin-bottom: 1rem;
        border-bottom: .1rem solid #eee;

        .title {
            display: flex;
            align-items: center;
            height: 4.9rem;
            font-size: 2.0rem;
            font-weight: 700;
        }
    
        .copy-btn {
          @include skyblue-btn(plus);
        }

        .update-btn {
            color: $color-main;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.9rem;
            padding-bottom: 0.5rem;

            i {
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                background: url('../../../../images/i_update.png') no-repeat;
                background-size: 100% 100%;
                vertical-align: -12%;
                margin-right: 0.2rem;
            }
        }
    }

    .flex-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tab-area {
            display: flex;
            column-gap: 1.5rem;

            button {
                padding: 1.5rem;
                border-bottom: .3rem solid #fff;
                font-size: 1.5rem;
                font-weight: 500;
                color: #999;

                &.on {
                    border-bottom: .3rem solid $color-main;
                    color: #000;
                }
            }
        }

        .download-btn {
            @include gray-border-btn();

            i {
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                margin-right: .9rem;
                vertical-align: -8%;
                background-image: url('../../../../images/print.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }

    .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 9.2rem);
        font-size: 1.4rem;
        color: #999;

        i {
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            background: url('../../../../images/icon_mark.png') no-repeat;
            background-size: 100% 100%;
            margin-right: .9rem;
        }
    }


}