@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.inheritance-tax-info {
  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }
    }
  }

  .title {
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;

    button.reset-btn {
      font-size: 1.2rem;
      color: #808080;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/reset.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 1.0rem;
        margin-right: .3rem;
        vertical-align: -10%;
      }
    }
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }

  .tab-area {
    display: flex;
    column-gap: 1.5rem;
    padding-bottom: 2.7rem;

    button {
      padding: 0 1.5rem 1.5rem 1.5rem;
      border-bottom: .3rem solid #fff;
      font-size: 1.5rem;
      font-weight: 500;
      color: #999;

      &.on {
        border-bottom: .3rem solid $color-main;
        color: #000;
      }
    }
  }
}

.inheritance-box {
  padding-bottom: 2.6rem;

  .sub-title {
    padding-bottom: 2.6rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .foot-note {
    padding-bottom: 5.7rem;
    font-size: 1.2rem;
    color: $color-main;

    .move-page-txt {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .grid-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;

    .input-box {
      width: 36.2rem;
      margin-bottom: 1.6rem;
    }

    &.box1 {
      padding-bottom: 5.7rem;
    }
  }
}