@import '~reset-css/sass/_reset';
@import './themes/theme';
@import './components/popup';
@import './components/buttons';
@import './helpers/mixins';
@import './themes/colors.module';

//화면 사이즈 변경 관련 부분
#dialog > div > div {
  max-height: 940px;
}

html {
  font-size: 10px;
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body, #root {
  height: 100%;
  overflow-x: hidden;
  font-family: 'Noto Sans KR', sans-serif;
  background-color: #f2f2f2;
  color: #000;

  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

input, textarea, button {
  font-size: 1.33rem;
}

input[type='radio'] {
  margin: .3rem .3rem 0 .5rem;
}

// @import './base/_reset';

// 토스트 라이브러리 CSS
@import '~react-toastify/dist/ReactToastify.css';

/* 전체 화면 */
.full-screen {
  background-color: #f2f2f2;
  height: 100%;

  .full-screen-notice {
    display: flex;
    align-items: center;
    font-size: 1.0rem;
    color: #808080;
    cursor: pointer;

    i {
      display: inline-block;
      width: 2.0rem;
      height: 2.0rem;
      background-image: url('../images/report/icon_fullScreen.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-left: .8rem;
    }
  }

  &.active .full-screen-notice i {
    background-image: url('../images/report/icon_originalScreen.png');
  }
}

/* 공통 사용 */
.scale-wrap {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scroll-inner {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

// layerpop
// .layer-back {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(50, 50, 50, 0.3);
//   z-index: 10;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .layer-pop {
//   @include basic-shadow-box();

//   position: absolute;
// }

// TableTitle
.table-title {
  width: 100%;
  margin-bottom: 4.0rem;

  .title-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 1.3rem;

    .title {
      font-size: 1.7rem;
      font-weight: 700;

      i {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .7rem;
        background: url('../images/icon_table.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .refer {
      font-size: 1.3rem;
    }

    .date-or-unit {
      font-size: 1.3rem;
      color: #808080;
    }

    .grid-area {
      width: 100%;
    }
  }
}

//기본 닫기 버튼
.close-btn {
  @include gray-border-btn();
}

// 알럿창 뒷배경
.full-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.3);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .pop-inner {
    @include basic-shadow-box();
  }
}

// 다이얼로그
.dialog {
  position: absolute;
  width: 31.5rem;
  padding: 3.2rem 3.0rem 2.2rem;

  .btn-area {
    display: flex;
    justify-content: center;
    column-gap: 1.0rem;
    height: 4.6rem;
    padding-top: 2.7rem;

    // 알럿창 버튼 하나만 있을 때
    &.one-button {
      justify-content: center;
    }

    button {
      min-width: 14.2rem;
      min-height: 4.6rem;
      padding: 0 1.9rem;
      font-size: 1.4rem;
      font-weight: 700;
      border-radius: .6rem;

      &.close-btn {
        color: #4c4c4c;
        border: none;

        &:active {
          background-color: #f5f5f5;
        }
      }

      &.main-btn {
        color: #fff;
        background-color: $color-main;

        &:active {
          background-color: $color-main-hover;
        }

        &:disabled {
          background-color: $color-back2;
        }
      }

      &.main-btn2 {
        @include skyblue-btn();
      }
    }
  }
}

//기본 다이얼로그
.dialog-type-a {
  @extend .dialog;

  padding: 4.5rem 3.0rem 3.0rem;
  text-align: center;

  .title {
    padding-bottom: .8rem;
    line-height: 2.3rem;
    font-size: 1.6rem;
    font-weight: 500;
    white-space: pre-wrap;
    word-break: keep-all;

    &.light {
      padding-bottom: 1.5rem;
      font-weight: 400;
    }

    &.left {
      padding-bottom: 1.5rem;
      text-align: left;
      font-size: 1.7rem;
    }

    span {
      font-weight: 400;
    }
  }

  .title2 {
    padding-bottom: .4rem;
    line-height: 2.0rem;
    font-size: 1.4rem;
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .sub-title {
    line-height: 2.0rem;
    font-size: 1.3rem;
    color: #666;
    white-space: pre-wrap;
    word-break: keep-all;

    &.black {
      color: #4c4c4c;
    }
  }

  .basic-title {
    line-height: 2.3rem;
    font-size: 1.6rem;
    white-space: nowrap;
  }

  .bold {
    font-weight: 700;
  }

  .input-box {
    width: 100%;
    height: 4.6rem;

    input {
      width: calc(100% - 3.6rem);
      height: 4.6rem;
      padding: 0 1.8rem;
      line-height: 4.6rem;
      border: none;
      background-color: #f9f9f9;
      border-radius: .8rem;
      font-size: 1.4rem;
    }
  }

  .chkbox-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.7rem;

    .radio-box {
      @include green-chkbox();
      display: flex;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: .5rem;
      }

      span {
        padding-left: .7rem;
        font-size: 1.4rem;
      }
    }
  }
}

//로딩 다이얼로그
.loading-dialog {
  @extend .dialog;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2.7rem;
  }

  .sub-title {
    line-height: 2.3rem;
    font-size: 1.6rem;
  }

  .btn-area {
    padding-top: 0;
  }
}

//로딩 박스
.loading-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  span {
    font-size: 1.8rem;
    color: #333;
  }
}

.modal-open {
  overflow: hidden;
}

.formik-field {
  position: relative;
  display: inline-block;
  width: 100%;
}

.error-message {
  position: absolute;
  top: 100%;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: #f53f50;
  border-radius: .6rem;
  padding: .1rem .8rem;
  margin-top: .3rem;
  line-height: 2.0rem;
  white-space: nowrap;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -.7rem;
    left: .4rem;
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-image: url('../images/error_tooltip_arrow.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  i {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('../images/error_tooltip_icon.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    vertical-align: -12%;
    margin-right: .5rem;
  }
}

//토스트 커스텀
.Toastify__toast-container {
  width: 30.0rem;
  height: 5.6rem;
  padding: 0;
}

.Toastify__toast {
  height: 5.6rem;
  padding: 0;
  margin: 0;
  padding-left: 5.5rem;
  border-radius: .8rem;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  color: #000;
}

.Toastify__toast--success::before {
  content: '';
  position: absolute;
  left: 1.9rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../../src/images/toast_success.png');
  background-repeat: no-repeat;
  background-size: 100%;
  width: 2.2rem;
  height: 2.2rem;
}

.Toastify__progress-bar--success {
  background-color: $color-main;
}

.Toastify__toast-icon,
.Toastify__close-button {
  display: none;
}

.Toastify__toast > button > svg {
  display: none;
}

.Toastify__toast-container--bottom-left {
  bottom: 2em;
  left: 1em;
}

//edit-button
.edit-btn {
  display: flex;
  column-gap: 0.4rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.6rem;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  i {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    background-image: url('../images/i_pencil_blue.png');
  }

  font-size: 1.3rem;
  color: $color-main;

  &:active {
    background-color: #f5f5f5;
  }

  &:active[disabled] {
    background-color: initial;
  }

  @media (hover: hover) {
    &:hover{
      background-color: #f5f5f5;
    }

    &:hover[disabled]{
      background-color: initial;
    }
  }
}

//공통 인풋박스 
.input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-size: 1.4rem;
    color: #4c4c4c;

    .required-field {
      color: #f53f50;
    }
  }

  &.error .for-unit {
    box-sizing: border-box;
    border: .1rem solid #f53f50;
  }

  .for-unit {
    position: relative;
    display: flex;
    align-items: center;
    @include basic-input-box-bg();

    &.hasUnit input.input-txt {
      padding: 1.3rem 3.2rem 1.3rem 1.8rem;
    }

    input.input-txt, button.input-txt {
      @include basic-input-box();
      box-sizing: border-box;
      width: 100%;
      padding: 1.3rem 1.8rem;
      font-size: 1.4rem;

      &:read-only {
        color: #666;
      }

      &:disabled {
        color: #666;
      }
    }

    button.input-txt {
      @include basic-input-box();

      height: 4.6rem;
      box-sizing: border-box;
      text-align: left;
      background-image: url('../images/calendar.png');
      background-repeat: no-repeat;
      background-size: 10%;
      background-position: 85% center;
      cursor: pointer;
    }

    // .unit {
    //   position: relative;
    //   margin-left: -1.4rem;
    //   margin-right: 1.8rem;
    //   font-size: 1.4rem;
    //   color: $color-gray-99;
    //   white-space: nowrap;
    // }

    .unit {
      position: absolute;
      right: 1.8rem;
      font-size: 1.4rem;
      color: $color-gray-99;
      white-space: nowrap;
    }

    :global(.error-message) {
      margin-top: 5.4rem;
    }
  }

  &.flex {
    .for-unit {
      white-space: nowrap;
    }
  }

  &.flexable-box {
    justify-content: space-between;
    width: auto;

    input {
      @include basic-input-box();
      width: auto;
      min-width: 9.1rem;
    }

    .unit {
      margin-left: -2.3rem;
    }
  }

  //input[type=number]에서 스피너 제거
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

.date-box {
  @include date-box(9.6rem, 9% 26%, 85%);

  & > div {
    input {
      padding: 1.2rem 1.8rem 1.4rem;
    }
  }
}

//에러박스
.error-box {
  position: relative;
}

// 로그인, 회원가입 등 인트로 페이지 공통
.intro-page {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .intro-inner {
    display: flex;
    align-items: center;
    max-width: 192.0rem;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    overflow-y: auto;

    .grid-box {
      display: flex;
      height: 100%;
      min-height: 100%;
      overflow: hidden;
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right img {
    width: 78.2rem;
    height: 54.5rem;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .page-title {
      width: max-content;
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;
      font-size: 2.4rem;
      font-weight: 700;

      &.join {
        padding-left: 13px;
        padding-right: 13px;
      }

      &.w-narrow {
        max-width: 34rem;
        width: 100%;
      }
    }

    .page-desc {
      padding-bottom: 1.0rem;
      font-size: 1.6rem;
    }

    .label-box {
      padding-top: .6rem;

      label {
        display: inline-block;
        font-size: 1.5rem;
        padding-bottom: 1.0rem;
      }
    }

    .input-box {
      width: 100%;
      padding-bottom: 1.2rem;

      &.last {
        padding-bottom: 2.7rem;
      }

      input {
        @include basic-input-box2();
      }
    }

    .question {
      text-align: center;
      font-size: 1.4rem;
      color: #666;

      &.align-left {
        padding-top: 1.0rem;
        text-align: left;
      }

      a.answer {
        display: inline-block;
        padding-left: .4rem;
        font-weight: 700;
        color: $color-main;
      }
    }

    //.intro-left-cover {
    //  height: calc(100% - 6rem);
    //
    //  .intro-scroll-box {
    //    height: calc(100% - 6rem);
    //    overflow-y: auto;
    //  }
    //}
  }
}

// calendar custom
.react-datepicker {
  border: .1rem solid #eee !important;
  box-shadow: 0 .5rem 1.0rem rgba(0, 0, 0, 0.12) !important;
  border-radius: .8rem !important;
  width: 29.7rem;
  height: auto;

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    text-align: left;
    background-color: #fff;
    border-bottom: none;
    padding: 2.2rem 3.0rem 1.2rem;

    .react-datepicker__current-month {
      padding-top: .3rem;
      padding-bottom: 1.5rem;
      font-size: 1.4rem;
      font-weight: 700;
      text-indent: .9rem;
      letter-spacing: -.0rem;
    }

    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-bottom: 1.2rem;

      .react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select .react-datepicker__month-select,
      .react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select .react-datepicker__year-select {
        width: 7.5rem;
        height: 3.6rem;
        box-sizing: border-box;
        padding: 0 0.8rem 0 1.2rem;
        line-height: 1.9rem;
        margin-right: 1.2rem;
        background-color: #F9F9F9;
        border-radius: 8px;
        font-size: 1.2rem;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('../images/select_box_icon.png');
        background-repeat: no-repeat;
        background-position: 85% 53%;
        background-size: 18%;
        color: #000;
      }
    }
  }

  .react-datepicker__navigation {
    top: 2.1rem;
    height: 2.2rem;
    width: 2.2rem;
    border: .1rem solid #eee;

    .react-datepicker__navigation-icon {
      font-size: 2rem;
    }

    &.react-datepicker__navigation--previous {
      left: 21.7rem;

      .react-datepicker__navigation-icon--previous {
        right: .3rem;
        top: .5rem;

        &::before {
          right: -.7rem;
        }
      }
    }

    &.react-datepicker__navigation--next {
      right: 3.7rem;

      .react-datepicker__navigation-icon--next {
        left: .3rem;
        top: .5rem;

        &::before {
          left: -.7rem;
        }
      }
    }
  }

  .react-datepicker__day {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2;
    font-size: 1.3rem;
  }

  .react-datepicker__day-names {
    margin-bottom: -.8rem;
}

  .react-datepicker__day-name {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    line-height: 2rem;
    font-size: 1.2rem;
    color: #666;
  }

  .react-datepicker__month {
    margin: 0 3.1rem 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.4rem;

    &:active {
      border-radius: 100%;
    }
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #999;
    border-width: .1rem .1rem 0 0;
    height: .5rem;
    top: .6rem;
    width: .5rem;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 100%;
    background-color: #f0f0f0;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 100%;
    background-color: $color-main;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 100%;
    background-color: $color-main;
  }

  .react-datepicker__day--selected:hover, 
  .react-datepicker__day--in-selecting-range:hover, 
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
  background-color: $color-main;
}

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
}

.react-datepicker-popper {
  z-index: 2 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

//메인 리포트 스와이퍼 커스텀
.swiper-button-prev {
  left: -7.2rem !important;
  width: 4.8rem !important;
  height: 4.8rem !important;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-image: url('../images/report/slide_btn_left.png');
  background-size: 100% 100%;

  &::after {
    display: none;
  }

  &:active {
    background-color: rgba(0,0,0,0.1);
  }

  &.swiper-button-disabled {
    opacity: 0 !important;
  }
}

.swiper-button-next {
  right: -7.2rem !important;
  width: 4.8rem !important;
  height: 4.8rem !important;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-image: url('../images/report/slide_btn_right.png');
  background-size: 100% 100%;

  &::after {
    display: none;
  }

  &:active {
    background-color: rgba(0,0,0,0.1);
  }

  &.swiper-button-disabled {
    opacity: 0 !important;
  }
}

// 주석 넘버링 & 스타일
.numbering {
  vertical-align: top;
  padding-left: .2rem;
  font-size: .8rem;
  color: $color-main;
}

.footNote {
  font-size: 1.2rem;
  line-height: 1.9rem;
  color: #666;

  //&.ver2 {
  //  display: flex;
  //  column-gap: 0.3rem;
  //}
}

//블루 체크 버튼
.blue-chk-btn {
  @include blue-chkbox2();

  display: flex;
  flex-direction: row;
  align-items: center;

  input[type='checkbox'] {
    height: 16px;
    margin-right: 24px;
    margin-left: 0;
  }

  input[type='checkbox']:before {
    width: 16px;
    height: 16px;
  }
}

//edit-report-info-btn (메인 리포트에만 적용되는 버튼)
.edit-report-info-btn {
  @include skyblue-btn(arrow3_right);

  margin-top: 3px;
  padding: 5px 4px 5px 18px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  vertical-align: middle;

  i {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    vertical-align: -20%;
  }
}

.bookmark {
  width: 25px;
  height: 30px;
  margin: 3px 0 0 15px;
  background: url('../images/i_bookmark_off.png') no-repeat 0 0 / cover;
  vertical-align: middle;

  &.on {
    background: url('../images/i_bookmark_on.png') no-repeat 0 0 / cover;
  }
}

////////////////////////////
@media (max-height: 1000px) {
  //화면 사이즈 변경 관련 부분
  #dialog > div > div {
    max-height: calc(100% - 1.6rem);
  }
}

@media (max-width: 1366px) {
  //메인 리포트 스와이퍼 커스텀
  .swiper-button-prev {
    display: none !important;
    top: 91% !important;
    left: 88% !important;
    background-image: url('../images/report/arrow_gray_l.png');

    &.show {
      display: block !important;
    }
  }

  .swiper-button-next {
    display: none !important;
    right: 2.4rem !important;
    top: 91% !important;
    background-image: url('../images/report/arrow_gray_r.png');

    &.show {
      display: block !important;
    }
  }
}

@include tablet1200 {
  // 로그인, 회원가입 등 인트로 페이지 공통
  .intro-page {

    .right img {
      width: 90%;
      height: auto;
    }
  }
}

@include tablet880 {
  // 로그인, 회원가입 등 인트로 페이지 공통
  .intro-page {
    .intro-inner {
      align-items: flex-start;

      .grid-box {
        width: 100%;
        height: auto;
        min-height: 100%;
        grid-template-columns: 1fr;
      }
    }

    .left {
      height: auto;
    }

    .right {
      display: none;
    }
  }
}

@include tablet767 {
  body, #root {
    background-color: #fff;

    .scale-wrap {
      height: calc(100% - 6.8rem);
      padding-top: 6.8rem;
    }
  }
}

@include mobile500 {
  .intro-page {
    height: 100%;
    padding: 3rem 0;

    .left {
      width: calc(100% - 6.0rem);
      padding: 0 3.0rem;
    }
  }
}

@include mobile417 {
  //기본 다이얼로그
  .dialog-type-a {
    padding: 3.5rem 2.5rem 2.5rem;

    .basic-title {
      line-height: 2.3rem;
      font-size: 1.5rem;
      white-space: nowrap;
    }
  }
}

@include mobile360 {
  //기본 다이얼로그
  .dialog-type-a {
    width: calc(100% - 5.0rem);

    .basic-title {
      line-height: 2.3rem;
      font-size: 1.5rem;
      white-space: nowrap;
    }

    .btn-area {
      display: flex;
      justify-content: space-between;
      height: 4.6rem;
      padding-top: 2.7rem;

      button {
        min-width: 13.5rem;
        min-height: 4.6rem;
        padding: 0 1.9rem;
        font-size: 1.4rem;
        font-weight: 700;
        border-radius: .6rem;

        &.close-btn {
          color: #4c4c4c;

          &:active {
            background-color: #f5f5f5;
          }
        }

        &.main-btn2 {
          @include skyblue-btn();
        }
      }
    }
  }
}

@media (hover: hover) {
  .main-btn:hover {
    background-color: $color-main-hover;
  }

  .close-btn:hover {
    background-color: #f5f5f5;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    &:hover {
      border-radius: 100%;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  
}

@media only screen and (min-width: 1320px) {
  html {
    font-size: 12px;
  }

  #root .intro-page {
    .intro-inner {
      width: calc(100% - 9.0rem);
      padding: 0 3.0rem 0 6.0rem;
    }

    .right img {
      width: 80%;
      height: auto;
    }
  }
}