@import '../../../scss/themes/colors.module';
@import '../../../scss/helpers/mixins';

.advice-journal {
  width: 100%;
  padding-top: 4.0rem;

  .record {
    padding-bottom: 7.5rem;
  }

  .title-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 1.1rem;
    margin-bottom: 1.3rem;
    border-bottom: .1rem solid #eee;

    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }

    .add-btn {
      @include skyblue-btn(plus);
    }
  }

  .list-area {
    width: 100%;

    li {
      display: grid;
      grid-template-columns: 2.5rem 4.0rem 10.0rem 10.0rem auto 5.0rem 5.0rem 3.5rem;
      align-items: center;
      padding: 1.0rem .8rem;
      font-size: 1.3rem;
      cursor: pointer;

      &:active {
        background-color: #f9f9f9;
      }

      &.no-info-li {
        grid-template-columns: 1fr;
        padding: 1.5rem;
        cursor: default;

        &:active {
          background-color: transparent;
        }
      }

      span {
        display: inline-block;

        &:nth-child(5) {
          padding-right: 4.0rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon-calendar-list {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          background: url('../../../images/calendar2.png') no-repeat;
          background-size: 100% 100%;
        }

        .icon-document-list {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          background: url('../../../images/document.png') no-repeat;
          background-size: 100% 100%;
        }

        button {
          &.edit-btn {
            @include btn20(pencil2);
          }

          &.upload-file-btn {
            @include btn20(upload);
          }

          &.delete-btn {
            @include btn20(delete3);
          }

          &:active {
            background-color: #eee;
          }
        }
      }
    }
  }

  .share-cus-member {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    margin-bottom: 3rem;
    font-size: 1.4rem;

    .share-cus-title {
      font-weight: 700;

      i {
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        background: url('../../../images/share_cus_icon.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -40%;
        margin-right: 1rem;
      }
    }
  }

  .data {
    .list-area {

      li {
        grid-template-columns: auto 3.5rem;

        &.no-info-li {
          grid-template-columns: 1fr;
        }

        a {
          display: grid;
          grid-template-columns: 2.5rem 5.0rem auto 5.0rem;
          align-items: center;
          height: 100%;
        }

        .download-btn {
          @include btn20(down);

          opacity: 0;

          &:active {
            background-color: #eee;
          }
        }

        .delete-btn {
          @include btn20(delete3);

          opacity: 0;

          &:active {
            background-color: #eee;
          }
        }

        &:active {

          .download-btn,
          .delete-btn {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (hover: hover) {
  .list-area li {
    &:hover {
      background-color: #f9f9f9;
    }

    &.no-info-li:hover {
      background-color: transparent;
    }

    span button:hover{
      background-color: #eee;
    }
  }

  .download-btn:hover{
    background-color: #eee;
  }

  .delete-btn:hover {
    background-color: #eee;
  }

  .data .list-area li:hover {
    .download-btn,
    .delete-btn {
      opacity: 1;
    }
  }
}
    
  
