@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module';

.business-field-pop {
    display: flex;
    flex-direction: column;
    width: 70rem;
    height: 100%;

    .title {
        padding: 1.3rem 1.7rem;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 2rem;
        border-bottom: 0.1rem solid #eee;
    }

    form {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .type-list {
        flex: 1;
        max-height: calc(100% - 6rem);
        padding-top: 1.7rem;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;
        white-space: pre-wrap;

        &::-webkit-scrollbar {
            display: none;
        }

        .type-box {

            &.ver2 {
                &.on {
                    background-color: #fbfbfb;

                    .radio-box {
                        font-weight: 700;

                        i {
                            background-image: url('../../../../images/blue_chk_btn_on.png');
                        }
                    }
                }

                .radio-box {
                    display: flex;
                    align-items: center;
                    column-gap: 0.8rem;
                    padding: 0.8rem 1.7rem;
                    font-size: 1.3rem;
                    box-sizing: border-box;

                    i {
                        display: inline-block;
                        width: 1.6rem;
                        height: 1.6rem;
                        background-repeat: no-repeat;
                        background-image: url('../../../../images/blue_chk_btn.png');
                        background-size: 100%;
                    }

                    @media (hover: hover) {
                        &:hover > i {
                            border-radius: 50%;
                            background-color: #f9f9f9;
                        }
                    }
                }
            }

            &.on {
                background-color: #fbfbfb;

                .radio-box {
                    font-weight: 700;
                }
            }

            .radio-box2 {
                @include blue-chkbox();

                display: flex;
                align-items: center;
                column-gap: 0.8rem;
                margin-left: -0.3rem;
                height: 1.6rem;
                padding: 0.8rem 1.7rem;
                font-size: 1.3rem;

                input[type='checkbox'] {
                    height: 1.6rem;
                    margin-right: 1.6rem;
                }

                input[type='checkbox']:before {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: 1.6rem;
                }
            }

            .keyword-box {
                padding: 1.2rem 2rem 2rem;

                .keyword {
                    display: inline-block;
                    margin-right: 1.2rem;
                    margin-bottom: 1.2rem;

                    .label-box {
                        display: inline-block;
                        padding: 0.6rem 1.2rem;
                        text-align: center;
                        border: 0.1rem solid #eee;
                        border-radius: 2rem;
                        background-color: #fff;
                        font-size: 1.3rem;
                        font-weight: 500;
                        line-height: 2rem;
                        color: #4c4c4c;
                        cursor: pointer;
                    }

                    input {
                        display: none;

                        &:checked + label {
                            border: 0.1rem solid #5c67ff;
                            color: #5c67ff;
                        }
                    }
                }
            }
        }
    }

    .btn-area {
        display: flex;
        justify-content: flex-end;
        padding: 0 1.7rem 2rem;

        button {
            margin-left: 1.2rem;
            box-sizing: border-box;
            border-radius: .6rem;
            font-size: 1.3rem;
            border: none;

            i {
                display: inline-block;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin-right: .8rem;
            }

            &.save-btn {
                @include primary-btn-design(1.0rem 1.9rem);

                line-height: 2rem;
                font-weight: 700;

                i {
                    width: 1.4rem;
                    height: 1.4rem;
                    background-image: url('../../../../images/save_chk_btn.png');
                    vertical-align: -14%;
                }
            }
        }
    }
}