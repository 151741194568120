@import '../../../scss/helpers/mixins';
@import '../../../scss/themes/colors.module';

.pop-wrap {
  display: flex;
  position: relative;
  width: 112.3rem;
  height: 100%;
}

.left-menu {
  display: flex;
  flex-direction: column;
  width: 22.4rem;
  height: 100%;
  border-right: .1rem solid #eee;
  overflow: hidden;

  .customer-card {
    width: 100%;
    min-height: 25.1rem;
    padding-top: 4rem;
    padding-bottom: 1.6rem;
    text-align: center;
    box-sizing: border-box;

    img {
      width: 4.8rem;
      height: 4.8rem;
    }

    .cus-name-box {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: .8rem;
      padding-top: .8rem;

      .cus-name {
        font-size: 1.5rem;
        font-weight: 700;

        span {
          font-weight: 400;
        }
      }

      .cotalk-state {
        width: 8.7rem;
        height: 2rem;
        line-height: 2rem;
        border-radius: 5rem;
        font-size: 1.1rem;

        &.request {
          background-color: $color-main;
          color: #fff;
        }

        &.waiting {
          background-color: #eee;
          color: #4c4c4c;
        }

        &.connect {
          background-color: $color-back;
          color: $color-main;
        }
      }

      .share-cus-chk {
        padding: 0.05rem 0.9rem;
        font-size: 1.1rem;
        line-height: 1.9rem;
        background-color: $color-main;
        color: #fff;
        border-radius: 5rem;

      }
    }

    .company-name {
      word-break: keep-all;
      padding: 1.6rem 3.4rem 0.4rem;
      line-height: 2.4rem;
      font-size: 1.7rem;
      font-weight: 700;
    }

    .data-area {
      padding-top: 1.6rem;

      p {
        padding-bottom: 0.8rem;
        font-size: 1rem;
        line-height: 1.4rem;
        color: #4c4c4c;

        i {
          display: inline-block;
          width: 1rem;
          height: 0.6rem;
          background: url('../../../images/i_blue_chk.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 0.4rem;
        }
      }
    }
  }

  .menu-scroll {
    flex: 1;
    padding-bottom: 2rem;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menu-area {
    width: 100%;

    $image: (id_card, bag, member, detail, ic_copy, report2, homeTax, policyFund, i_mna_info);

    @each $image in $image {
      li.#{$image} {
        @include sub-menu-list($image);
        width: 100%;
        padding-left: 3.4rem;
      }
    }

    @each $image in $image {
      li.#{$image}.on {
        @include sub-menu-list-on($image);
        width: 100%;
        padding-left: 3.4rem;
      }
    }
  }

  .policy-funds-link {
    @include sub-menu-list(ic_funds);
    width: calc(100% - 3.4rem);
    padding-left: 3.4rem;

    &:active {
      @include sub-menu-list-on(ic_funds);
      width: calc(100% - 3.4rem);
      padding-left: 3.4rem;
    }

    @media (hover: hover) {
      &:hover{
        @include sub-menu-list-on(ic_funds);
        width: calc(100% - 3.4rem);
        padding-left: 3.4rem;
      }
    }
  }

  .company-info-btn-area {
    display: flex;
    align-items: center;
    padding: 1.5rem 3.4rem;

    button {
      @include gray-border-btn();

      width: 100%;
      border: 0.1rem solid $color-main;
      color: $color-main;

      &:active {
        background-color: #f9f9f9;
      }

      @media (hover: hover) {
        &:hover {
          background-color: $color-back-hover;
        }
      }
    }
  }
}

.right-tab {
  width: 80.5rem;
  height: calc(100% - 2.5rem);
  padding: 2.5rem 4.7rem 0;

  .btn-area {
    display: flex;
    padding-bottom: 1.5rem;
    text-align: right;

    &.mna-customer-pop {
      justify-content: flex-end;
    }

    .chk-box-area {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      p {
        padding-right: 2.3rem;
        font-weight: 700;
      }

      .chk-box {
        @include green-chkbox();

        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        text-indent: 0.2rem;
      }
    }

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.reset-btn {
        margin-right: 1.2rem;
        font-size: 1.2rem;
        color: #808080;

        i {
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          margin-right: .2rem;
          background: url('../../../images/reset.png') no-repeat;
          background-size: 100% 100%;
          vertical-align: -12%;
        }
      }

      &.sky-blue-btn {
        display: flex;
        align-items: center;
        height: 4rem;
        padding: 0.4rem 1.9rem;
        font-weight: 500;
        line-height: 2rem;
        border-width: 0.1rem;
        border-style: solid;
        color: #0066F9;
        border-color: #0066F9;
      }

      &.share-btn {
        @include gray-border-btn();

        i {
          display: inline-block;
          width: 1.3rem;
          height: 1.3rem;
          background: url('../../../images/i_share.png') no-repeat;
          background-size: 100% 100%;
          margin-right: .8rem;
        }
      }

      &.print-btn {
        @include skyblue-btn(print_on);

        i {
          width: 1.2rem;
          height: 1.2rem;
        }
      }

      &.co-report-btn {
        @include skyblue-btn(report_on);

        border: .1rem solid $color-back;

        i {
          width: 1.3rem;
          height: 1.3rem;
          vertical-align: -8%;
        }
      }

      &.summary-report {
        @include gray-border-btn();

        i {
          width: 1.2rem;
          height: 1.2rem;
          background-image: url('../../../images/print.png');
          vertical-align: -5%;
        }
      }

      &.close-btn {
        @include gray-border-btn();
      }
    }
  }

  .scroll-area {
    height: calc(100% - 8.4rem);
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scroll-area::-webkit-scrollbar {
    display: none;
  }
}