@import '../../../scss/themes/colors.module';
@import '../../../scss/helpers/mixins';

.add-financial-data {

  .add-dialog {
    width: 60.4rem;
    padding: 3.0rem 3.4rem 2.0rem;
    color: #4c4c4c;

    .title {
      padding-bottom: 4.1rem;
      font-size: 2.0rem;
      font-weight: 700;
    }

    //.desc {
    //  padding-bottom: 3.2rem;
    //  font-size: 1.3rem;
    //}

    .input-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 2rem;

      .select-box-title {
        font-size: 1.4rem;
        padding-right: 2.4rem;

        //&.selected-year {
        //  color: #000;
        //}

        &.ver2 {
          padding-bottom: 3.2rem;
        }
      }

      .read-only-select-box {
        display: inline-block;
        padding: 1.2rem 1.8rem;
        font-size: 1.4rem;
        background-color: #f9f9f9;
        border-radius: 0.8rem;
      }

      .grid-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 1rem;
        flex: 1;
      }

      .radio-btn {
        @include main-radio-box();

        display: flex;
        align-items: center;
        height: 2.3rem;
        padding-right: 1rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }

    }

    .add-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 1.4rem;
      width: 100%;
      min-height: 14.4rem;
      padding: 3.5rem 0;
      margin-bottom: 2.9rem;
      box-sizing: border-box;
      border: .1rem solid #eee;
      border-radius: .8rem;

      &.drag {
        border: .1rem solid $color-main-hover;
      }

      .add-file-btn-area {
        width: 100%;
        height: 3.9rem;
        text-align: center;

        .add-file-btn {
          @include skyblue-btn();

          display: inline-block;
          padding: 1.3rem 1.9rem;

          &.mr {
            margin-right: 2rem;
          }

          i {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background-image: url('../../../images/upload_color.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-right: .5rem;
            vertical-align: -10%;
          }

          input {
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }

      .file-name {
        display: flex;
        flex-direction: column;
        row-gap: 0.6rem;
        font-size: 1.4rem;

        &.no-file {
          color: #999;
        }

        span i {
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          background: url('../../../images/delete2.png') no-repeat;
          background-size: 100% 100%;
          margin-left: 0.7rem;
          vertical-align: -15%;
        }
      }
    }
  }
}

.btn-area {
  display: flex;
  justify-content: flex-end;
  column-gap: 1.0rem;

  .close-btn {
    @include text-btn-design(1.0rem 2.0rem);
  }

  .save-btn {
    @include primary-btn-design(1.0rem 2.0rem);

    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background: url('../../../images/save_chk_btn.png') no-repeat;
      background-size: 100% 100%;
      margin-right: .5rem;
      vertical-align: -10%;
    }
  }
}