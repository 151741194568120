.payment-sys-table {
  width: 100.8rem;
  height: 100%;
  box-sizing: border-box;
  padding: 2.5rem 3.4rem 4rem;
  overflow: hidden;

  .top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 2rem;
    color: #4c4c4c;

    .title {
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 700;
    }

    .sub-title {
      font-size: 1.3rem;
      color: #4c4c4c;
    }
  }

  .sub-title {
    padding-bottom: 3.2rem;
    font-size: 1.3rem;
    color: #4c4c4c;
  }

  .table {
    width: 100%;
    height: calc(100% - 16rem);
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    .scroll-area {
      width: 100%;
      height: calc(100% - 4.8rem);
      overflow: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .head {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 4.8rem;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
      background-color: #FCFCFC;
      border-bottom: 1px solid #eee;

      p {
        flex: 1;
        padding: 0 1.8rem;

        &:first-child {
          max-width: 11.6rem;
          padding-left: 1.5rem;
          padding-right: 1.1rem;
          box-sizing: border-box;
        }
      }
    }

    .menu-title {
      padding: 2rem 0 1.1rem;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
      border-bottom: 1px solid #ccc;
      color: #4c4c4c;
    }

    .body {
      display: flex;
      text-align: center;
      width: 100%;
      font-size: 1.3rem;

      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }

      p, pre {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2rem;
        padding: 1.1rem 1.1rem;
        color: #333;

        &:not(:last-child) {
          border-right: 1px solid #eee;
        }

        &:first-child {
          max-width: 11.6rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          padding-left: 1.5rem;
          padding-right: 1.1rem;
          box-sizing: border-box;
          color: #808080;
        }

        //&.note {
        //  display: flex;
        //  flex-direction: column;
        //  align-items: flex-start;
        //  justify-content: flex-start;
        //  font-size: 1.2rem;
        //  line-height: 1.9rem;
        //
        //  span {
        //    text-align: left;
        //
        //    &.dot-list {
        //      display: flex;
        //      width: 100%;
        //      column-gap: 0.7rem;
        //
        //      b {
        //        font-weight: 700;
        //      }
        //
        //      em {
        //        color: #4c4c4c;
        //      }
        //    }
        //
        //    .no-content {
        //      flex: 1;
        //      display: inline-block;
        //      width: 100%;
        //      text-align: center;
        //    }
        //  }
        //}

        i {
          display: inline-block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-image: url('../../images/i_no_use.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        span.bold {
          font-weight: 700;
        }
      }
    }
  }

  .body2 {
    display: flex;
    text-align: center;
    width: 100%;
    padding-top: 1.6rem;
    font-size: 1.3rem;
    border-top: 1px solid #eee;
    line-height: 1.9rem;
    color: #333;

    p:first-child {
      width: 11.6rem;
      color: #000;
    }

    p.list {
      flex: 1;
      text-align: left;

      > span {
        padding-left: 1.5rem;
        font-size: 1.2rem;

        i {
          display: inline-block;
          width: 1rem;
          height: 0.7rem;
          background: url('../../images/i_black_chk.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 0.9rem;
        }

        span.bold {
          font-weight: 700;
        }
      }
    }
  }
}
