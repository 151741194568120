@import '../../scss/helpers/mixins';

.cotalk-state-box {
  width: 100%;
  height: 100%;

  .co-talk-state {
    display: inline-block;
    width: 3.8rem;
    height: 2.0rem;
    line-height: 2.0rem;
    border-radius: 1.0rem;
    font-size: 1.1rem;

    &.request {
      @include background-color-hover($color-main, $color-main-hover);
      color: #fff;
    }

    &.wait {
      @include background-color-hover(#ffe8ea, #FCD7DB);
      color: #F53F50;
    }

    &.disconnect {
      @include background-color-hover(#eee, #e5e5e5);
      color: $color-gray-4c;
    }

    &.join {
      @include background-color-hover($color-back, $color-back-hover);
      color: $color-main;
    }

    &.detail {
      width: auto;
      padding: 0 1.0rem;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.connect-cotalk-list {
  @include basic-shadow-box;

  display: block;
  position: absolute;
  right: 1.8rem;
  top: 2.9rem;
  width: 18.7rem;
  padding: .8rem 0;
  z-index: 1;

  &.detail {
    right: auto;
  }

  li {
    width: calc(100% - 1.7rem);
    height: 3.6rem;
    padding-left: 1.7rem;
    line-height: 3.6rem;
    text-align: left;
    font-size: 1.3rem;

    &:active {
      background-color: #f9f9f9;
    }

    i {
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 1.1rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      vertical-align: -4%;

      &.again {
        background-image: url('../../images/repeat.png');
      }

      &.cancel {
        background-image: url('../../images/cancel.png');
      }
    }
  }
}

@media (hover: hover) {
  .connect-cotalk-list li:hover{
    background-color: #f9f9f9;
    cursor: pointer;
  }
}