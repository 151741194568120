@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module.scss';

.visual {
  position: relative;
  width: 100%;
  height: 1138px;
  background-color: #fff;
  background-image: url('../../../../images/partners_home/visual_back.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  box-sizing: border-box;

  &.katalk-visual {
    background-image: url('../../../../images/partners_home/katalk_visual.png');
  }

  .visual-inner {
    position: relative;
    width: 100%;
    max-width: 1004px;
    height: 100%;
    box-sizing: border-box;
    //height: 1138px;
    margin: 0 auto;

    h3 {
      padding-top: 166px;
      font-size: 32px;
      font-weight: 700;
      line-height: 140%;
    }

    h2 {
      font-size: 72px;
      font-weight: 700;
      line-height: 140%;
      padding-bottom: 24px;
      color: #5c67ff;

      span.color {
        color: #000;
      }

      span.label {
        position: relative;
        bottom: 9px;
        display: inline-block;
        padding: 9px 12px;
        margin-left: 19px;
        font-size: 15px;
        font-weight: 600;
        line-height: 14px;
        color: #fff;
        background-color: #4c4c4c;
        border-radius: 6px;
      }
    }

    .desc {
      padding-bottom: 48px;
      font-size: 22px;
      font-weight: 500;
      line-height: 140%;

      &.katalk-desc {
        color: #666;
      }

      .br-tablet {
        display: none;
      }
    }

    .btn-area {
      display: flex;
      column-gap: 20px;

      button {
        padding: 12px 30px;
        border-radius: 27px;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: 700;
        line-height: 150%;

        &.download-btn {
          background-color: #5c67ff;
          color: #fff;
        }

        &.video-btn {
          border: 1px solid #5c67ff;
          color: #5c67ff;
          background-color: #fff;
        }
      }
    }
  }
}

@include tablet1200 {
  .visual {
    height: 90rem;
    background-image: url('../../../../images/partners_home/visual_back_tablet.png');

    .visual-inner {
      max-width: 76.8rem;

      .desc {
        font-size: 2.1rem;
        line-height: 140%;

        .br-tablet {
          display: block;
        }
      }
    }
  }
}

@include tablet880 {
  .visual {
    .visual-inner {
      padding: 0 3rem;
    }
  }
}

@include tablet767 {
  .visual {
    height: 72rem;
    .visual-inner {

      h2.katalk-h2 {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@include tablet600 {
  .visual {
    background-image: url('../../../../images/partners_home/visual_back_mob.png');

    .visual-inner {
      h3 {
        padding-top: 14rem;
        padding-bottom: 0.4rem;
        font-size: 2.8rem;
      }

      h2 {
        font-size: 6.5rem;
        padding-bottom: 2.2rem;
      }

      .desc {
        padding-bottom: 4rem;
        font-size: 2.1rem;
      }

      .btn-area {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 1.6rem;

        &.katalk {
          flex-direction: row;
          column-gap: 1.2rem;

          button {
            width: auto;
            padding: 1.2rem 2rem;
            font-size: 1.5rem;
          }
        }

        button {
          width: 13.6rem;
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          font-size: 1.8rem;
        }
      }
    }
  }
}

@include mobile500 {
  .visual {

    &.katalk-visual {
      background-position-x: 45%;
    }

    .visual-inner {
      padding: 0 2.4rem;

      h3 {
        font-size: 2.4rem;
      }

      h2.katalk-h2 {
        font-size: 4rem;

        span.label {
          bottom: 8px;
          padding: 7px 9px;
          margin-left: 12px;
          font-size: 12px;
        }
      }

      .desc {
        &.katalk-desc {
          font-size: 1.7rem;
          color: #000;
        }
      }

      .btn-area {
        button {
          padding: 12px 20px;
          font-size: 15px;
        }
      }
    }
  }
}

@include mobile417 {
  .visual {

    .visual-inner {

      h2 {
        font-size: 5.4rem;
        padding-bottom: 2rem;
      }

      .desc {
        font-size: 1.8rem;
      }
    }
  }
}