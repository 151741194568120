@import '../../../../scss/helpers/mixins';

.membership-card {
  position: relative;
  flex: 1;
  display: flex;
  height: 328px;
  padding: 66px 24px 24px 24px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #CCC;
  background: #FFF;
  cursor: pointer;

  &:active {
    background-color: #fbfbfb;
  }

  @media (hover: hover) {

    &:hover {
      background-color: #fbfbfb;
    }
  }

  .text-area {

    .sub-title {
      padding-bottom: 8px;
      color: #666;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .title-box {
      padding-bottom: 20px;
      font-weight: 700;

      .title {
        padding-bottom: 8px;
        font-size: 24px;
        line-height: 100%;
      }

      .condition {
        font-size: 17px;
        line-height: 100%;
      }
    }

    .price {
      padding-bottom: 10px;
      color: #000;
      font-size: 28px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -1.96px;
    }

    .price-per-case {
      color: #666;
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
    }
  }

  .purchase-btn {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    background-color: #000;
  }

  .recommend {
    position: absolute;
    top: 30px;
    left: 20px;
    display: inline-block;
    width: 53px;
    height: 24px;
    background: url('../../../../images/i_recommend.png') no-repeat;
    background-size: 100% 100%;
  }
}

@include tablet1200 {
  .membership-card {
    height: 32.8rem;
    padding: 6.6rem 2.4rem 2.4rem 2.4rem;
    border-radius: 1.2rem;

    .text-area {

      .sub-title {
        padding-bottom: 0.8rem;
        font-size: 1.5rem;
      }

      .title-box {
        padding-bottom: 2rem;

        .title {
          padding-bottom: 0.8rem;
          font-size: 2.4rem;
        }

        .condition {
          font-size: 1.7rem;
        }
      }

      .price {
        padding-bottom: 1rem;
        font-size: 2.8rem;
      }

      .price-per-case {
        font-size: 1.4rem;
      }
    }

    .purchase-btn {
      height: 4rem;
      border-radius: 0.6rem;
      font-size: 1.4rem;
    }

    .recommend {
      top: 3rem;
      left: 2rem;
      width: 5.3rem;
      height: 2.4rem;
    }
  }
}

@include tablet1000 {
  .membership-card {
    height: 28.9rem;

    .text-area {
      .title-box {
        display: flex;
        align-items: center;
        column-gap: 0.8rem;

        .title {
          padding-bottom: 0;
        }
      }
    }
  }
}