@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module.scss';


.company-value-info {
  width: 100%;

  .title {
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;

    button.reset-btn {
      font-size: 1.2rem;
      color: #808080;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/reset.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 1.0rem;
        margin-right: .3rem;
        vertical-align: -10%;
      }
    }
  }

  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }

      &.close-btn {
        @include gray-border-btn();
      }
    }
  }

  .input-area {
    width: 69.1rem;
    padding-bottom: 4.2rem;

    .input-box {
      margin-bottom: 1.6rem;

      &.select-box {

        input:read-only {
          @include select-input-box(2.8% 36%, $positionX: 96.5%);

          cursor: pointer;
          color: #000;
        }
      }

      // .initial-value {
      //   @include select-input-box(2.8% 36%, $positionX: 96.5%);
        
      //   box-sizing: border-box;
      //   width: 100%;
      //   height: 4.5rem;
      //   line-height: 1.2;
      //   font-size: 1.4rem;
      //   cursor: pointer;
      //   color: #000;
      // }
    }

    .select-btn {
      width: 100%;
    }

    .flex-input-box {
      display: flex;
      align-items: center;

      .input-box {
        width: 47rem;
      }

      .sub-label {
        padding-left: 1.2rem;
        margin-bottom: 1.6rem;
        font-size: 1.4rem;
        color: #4c4c4c;
      }
    }

    .info-txt {
      padding-top: 17px;
      font-size: 12px;
      color: $color-main;
    }

    .pad-top {
      padding-top: 1.9rem;
    }
  }

  .option-title {
    padding: 4.1rem 0 2.5rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .grid-box {
    width: 69.1rem;
    display: grid;
    grid-template-columns: auto repeat(4, 11.2rem);
    column-gap: 2.2rem;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: #4c4c4c;

    &.box2 {
      grid-template-columns: auto repeat(3, 16.5rem);
    }

    .inner-grid-box {
      display: grid;
      //grid-template-rows: 3.0rem repeat(6, 4.6rem);
      grid-template-rows: 3.0rem repeat(3, 4.6rem);
      align-items: center;
      justify-content: center;
      row-gap: 1.6rem;

      .year-txt {
        text-align: center;
      }

      .select-box2 {
        input {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }
}
