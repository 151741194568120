@import '../../../../scss/helpers/mixins';

.gray-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  padding: 40px 40px 32px;
  border-radius: 12px;
  background-color: #f5f5f5;
  background-position: right bottom;

  &.il_group {
    background-image: url('../../../../images/il_group.png');
    background-repeat: no-repeat;
    background-size: 50% 80%;
  }

  .type {
    font-size: 22px;
    font-weight: 700;
  }

  .desc-list {
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #4c4c4c;

    li {
      float: left;
      padding-right: 12px;
      white-space: pre-wrap;

      i {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url('../../../../images/i_blue_chk3.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -28%;
      }
    }
  }

  .channel-talk-btn {
    display: flex;
    padding: 6px 8px 6px 16px;
    align-items: center;
    column-gap: 2px;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    color: #333;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0 rgba(76, 76, 76, 0.15);

    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url('../../../../images/i_arrow_next.png') no-repeat;
      background-size: 100% 100%;
    }

    &:active {
      background-color: #fcfcfc;
    }

    @media (hover: hover) {

      &:hover {
        background-color: #fcfcfc;
      }
    }
  }
}

@include tablet1200 {
  .gray-box {
    row-gap: 1.6rem;
    padding: 4rem 4rem 3.2rem;
    border-radius: 1.2rem;

    .type {
      font-size: 2.2rem;
    }

    .desc-list {
      font-size: 1.5rem;
      line-height: 2rem;

      li {
        padding-right: 1.2rem;

        i {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }

    .channel-talk-btn {
      padding: 0.6rem 0.8rem 0.6rem 1.6rem;
      column-gap: 0.2rem;
      font-size: 1.4rem;
      line-height: 2.3rem;
      border-radius: 0.6rem;

      i {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}

@include tablet880 {
  .gray-box {

    &.il_group {
      background-size: 50% 65%;
    }
  }
}


@include tablet767 {
  .gray-box {

    &.il_group {
      background-size: 50% 90%;
    }
  }
}

@include tablet600 {
  .gray-box {

    &.il_group {
      background-size: 50% 80%;
    }
  }
}

@include mobile500 {
  .gray-box {
    padding: 28px 24px 24px;

    &.il_group {
      background-size: 50% 70%;
    }
  }
}

@include mobile417 {
  .gray-box {

    &.il_group {
      background-size: 50% 60%;
    }
  }
}

@include mobile360 {
  .gray-box {

    &.il_group {
      background-size: 45% 50%;
    }
  }
}