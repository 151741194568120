$color-main: #5C67FF;
$color-main-hover: #4752E4;
$color-back: #ecf2fc;
$color-back-hover: #e1eafb;
$color-back2: #b1c6ff;
$color-back2-hover: #e2eaf9;

$color-gray-f9: #f9f9f9;
$color-gray-99: #999999;
$color-gray-4c: #4c4c4c;

$color-sky-blue: #eff5ff;
$color-sky-blue2: #c3d4ff;
$color-orange: #ff835b;
$color-orange-light: #FFF4E8;
$color-green: #5CBF39;
$color-green-back: #f4f9ec;
$color-yellow: #FFD600;
$color-yellow-back: #fff7ce;
$color-purple-back: #EEECFF;

:export {
  main: $color-main;
  mainBack: $color-back;
  mainBack2: $color-back2;
  skyBlue: $color-sky-blue;
  skyBlue2: $color-sky-blue2;
  orange: $color-orange;
  orangeLight: $color-orange-light;
}