@import '../../../scss/themes/colors.module';
@import '../../../scss/helpers/mixins';

.page-title {
  padding-bottom: 2.7rem;
  font-size: 2.4rem;
  font-weight: 700;
}

.login-form {
  width: 34.0rem;

  .input-box {
    padding-bottom: 1.2rem;

    label {
      padding-bottom: 1.0rem;
      font-size: 1.5rem;
    }

    input {
      width: 100%;
      height: 4.6rem;
      font-size: 1.5rem;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
  margin-top: -1.2rem;
  padding-bottom: 5rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #666;

  i {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url('../../../images/i_mark2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.find-and-join {
  padding-top: 2.4rem;

  .find-area {
    display: flex;
    justify-content: center;
    padding-bottom: 1.5rem;

    a {
      display: inline-block;
      line-height: 1.7rem;
      border-bottom: .1rem solid #000;
      font-size: 1.4rem;
      color: #000;

      &:first-child {
        margin-right: 3.2rem;
      }
    }
  }
}

@include mobile500 {
  .login-form {
    width: 100%;
  
    .input-box {
      padding-bottom: 1.2rem;
  
      label {
        padding-bottom: 1.0rem;
        font-size: 1.5rem;
      }
  
      input {
        width: 100%;
        height: 4.6rem;
        font-size: 1.5rem;
      }
    }
  }
}