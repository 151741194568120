@import '../../../../scss/helpers/mixins';

.pricing-table {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  word-break: keep-all;

  .head {
    display: flex;
    column-gap: 2px;
    align-items: center;
    padding: 18px 0 13px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 700;
    background-color: #F7F7F8;
    color: #333;

    p {
      flex: 1;
      text-align: center;

      &:first-child {
        flex: 0.55;
        padding-left: 16px;
      }
    }
  }

  .body {
    flex: 1;
    display: flex;
    line-height: 18px;
    font-size: 14px;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    p, pre {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 14px;
      white-space: pre-wrap;

      &:first-child {
        flex: 0.55;
        text-align: left;
        padding-left: 16px;
        justify-content: flex-start;
        color: #666;
      }

      &:not(:last-child) {
        border-right: 1px solid #eee;
      }
    }

    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-image: url('../../../../images/i_no_use.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .bold {
      font-weight: 700;
    }
  }
}

@include tablet1200 {
  .pricing-table {
    margin-bottom: 1.6rem;

    .head {
      column-gap: 0.2rem;
      padding: 1.8rem 0 1.3rem;
      line-height: 2rem;
      font-size: 1.7rem;
    }

    .body {
      line-height: 1.8rem;
      font-size: 1.4rem;

      p, pre {
        padding: 1.6rem 1.4rem;

        &:first-child {
          padding-left: 1.6rem;
        }
      }

      i {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}

@include tablet767 {
  .pricing-table {

    .head {
      font-size: 1.5rem;
    }

    .body {
      line-height: 1.6rem;
      font-size: 1.2rem;

      p, pre {
        padding: 1.6rem 1.2rem;

        &:first-child {
          padding-left: 1.2rem;
        }
      }
    }
  }
}

@include tablet600 {
  .pricing-table {

    .head {
      font-size: 1.5rem;

      p {
        padding: 0 1rem;
      }
    }

    .body {
      line-height: 1.5rem;
      font-size: 1.1rem;

      p, pre {
        padding: 1rem 1rem;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}

@include mobile500 {
  .pricing-table {

    .head {
      font-size: 1.2rem;

      p {
        padding: 0 0.7rem;

        &:first-child {
          flex: 0.7;
        }
      }
    }

    .body {
      line-height: 1.3rem;
      font-size: 0.9rem;

      p, pre {
        padding: 1rem 0.7rem;

        &:first-child {
          flex: 0.7;
        }
      }

      i {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

@include mobile320 {
  .pricing-table {
    .head {
      p {
        padding: 0 0.5rem;

        &:first-child {
          flex: 0.9;
        }
      }
    }

    .body {
      p, pre {
        padding: 1rem 0.5rem;

        &:first-child {
          flex: 0.9;
        }
      }
    }
  }
}