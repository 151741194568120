@import '../../../scss/themes/colors.module';
@import '../../../scss/helpers/mixins';

.add-consult-data {

  .add-dialog {
    width: 51.8rem;
    padding: 3.0rem 3.4rem 2.0rem;

    .title {
      padding-bottom: 4.1rem;
      font-size: 2.0rem;
      font-weight: 700;
    }

    .input-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.6rem;

      input {
        @include basic-input-box();

        padding-left: 4.8rem;

        width: 100%;
        background: #f9f9f9 url('../../../images/add_file.png');
        background-position: 3% 50%;
        background-size: 4% 45%;
        background-repeat: no-repeat;
      }
    }

    .add-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 1.4rem;
      width: 100%;
      height: 14.4rem;
      margin-bottom: 2.9rem;
      border: .1rem solid #eee;
      border-radius: .8rem;

      &.drag {
        border: .1rem solid $color-main-hover;
      }

      .add-file-btn-area {
        position: relative;
        min-width: 13.7rem;
        height: 3.9rem;

        .add-file-btn {
          @include skyblue-btn();

          position: absolute;
          left: 0;
          top: 0;
          padding: 1.3rem 1.9rem;

          i {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background-image: url('../../../images/upload_color.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-right: .5rem;
            vertical-align: -10%;
          }
        }

        input {
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }

      .file-name {
        font-size: 1.4rem;

        &.no-file {
          color: #999;
        }
      }
    }
  }
}

.btn-area {
  display: flex;
  justify-content: flex-end;
  column-gap: 1.0rem;

  .close-btn {
    @include text-btn-design(1.0rem 2.0rem);
  }

  .save-btn {
    @include primary-btn-design(1.0rem 2.0rem);

    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background: url('../../../images/save_chk_btn.png') no-repeat;
      background-size: 100% 100%;
      margin-right: .5rem;
      vertical-align: -10%;
    }
  }
}