@import '../../scss/themes/colors.module';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 7.3rem;

  button {
    width: 3.0rem;
    height: 2.8rem;

    img {
      height: 40%;
    }
  }

  .numbering {
    display: flex;
    align-items: center;

    li {
      width: 2.8rem;
      height: 2.8rem;
      margin: 0 .2rem;
      line-height: 2.8rem;
      text-align: center;
      border-radius: 50%;
      font-size: 1.3rem;
      cursor: pointer;

      &.on {
        background-color: $color-back;
        color: $color-main;
      }
    }
  }
}