@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.main-issue-list {
  width: 100%;

  .btn-area {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1.5rem;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -10%;
        }
      }

      &.add-btn {
        @include skyblue-btn(plus);
      }
    }
  }

  .info {
    margin-top: -1rem;
    padding-bottom: 2.7rem;
    font-size: 1.3rem;
    color: #808080;
  }

  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .1rem solid #eee;
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;

    .title {
      font-size: 1.7rem;
      font-weight: 700;
    }
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }

    .issue-box {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;

      .input-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.6rem;

        > label {
          width: 10.6rem;
          padding-top: 1.2rem;
          line-height: 2.4rem;
          font-size: 1.3rem;
          color: #666;
          align-self: flex-start;
        }

        &.error {
          input {
            border: .1rem solid #f53f50;
          }
        }

        input {
          @include basic-input-box();

          font-size: 1.4rem;

          &.select-pop {
            width: 46.2rem;
            padding-right: 4.0rem;
            text-overflow: ellipsis;
            cursor: pointer;

            &:active {
              background-color: #eee;
            }

            background-image: url('../../images/select_box_icon.png');
            background-position: 96.5% center;
            background-size: 3% 35%;
            background-repeat: no-repeat;
          }

          &:disabled {
            color: #666;
            cursor: default;
          }
        }

        textarea {
          @include basic-input-box();

          resize: none;
          width: 48.4rem;
          min-height: 14.6rem;
          outline: none;
        }

        &.date-box {

          input {
            @include select-input-box(2.8% 33%, calendar);

            padding-right: 1.8rem;
          }
        }
      }

      .line {
        display: flex;
        column-gap: 1.6rem;
      }

      textarea {
        min-height: 8.8rem;
      }

      .delete-btn {
        position: absolute;
        right: 14rem;
        top: .85rem;
        width: 3.0rem;
        height: 3.0rem;
        border-radius: 100%;
        background-image: url('../../images/delete.png');
        background-size: 40% 40%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &:active {
          background-color: #f9f9f9;
        }
      }
    }
  }
}