@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module.scss';

.video-pop {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 72.0rem;
    background-color: #fff;
    overflow: hidden;

    .video-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 5.0rem;

        p {
            text-indent: 2.3rem;
            font-size: 1.6rem;
            font-weight: 800;
            color: $color-main;
        }

        .close-btn {
            width: 5.0rem;
            height: 5.0rem;
            background: url('../../images/partners_home/close_btn_main.png') no-repeat;
            background-size: 40% 40%;
            background-position: center center;
        }
    }

    //video-box 
    .video-box {
        position: relative;
        padding-bottom: calc(100% / 4 * 3);
        padding-top: 0;
        width: 100%;
        height: 0;
        overflow: hidden;

        &.ver2 {
            padding-bottom: calc(100% / 16 * 9);
        }
    
        iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }
    }
}

@include tablet767 {
    .video-pop {
        width: 98%;
    }
}