@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.pop-wrap {
  display: flex;
  width: 112.3rem;
  height: 86.3rem;
}

.left-menu {
  width: 22.4rem;
  height: 100%;
  border-right: .1rem solid #eee;

  .company-logo {
    width: 100%;
    min-height: 20.7rem;
    padding-top: 4rem;
    padding-bottom: 1.6rem;
    text-align: center;
    box-sizing: border-box;

    img {
      width: 4.8rem;
      height: 4.8rem;
    }

    .name {
      // word-break: keep-all;
      padding: 2.2rem 3.4rem 0;
      line-height: 2.4rem;
      font-size: 1.7rem;
      font-weight: 700;
    }

    .add-info {
      padding-top: .8rem;
      font-size: 1.5rem;
    }
  }

  .scroll-area {
    width: 100%;
    height: calc(100% - 22.7rem);
    padding-bottom: 2rem;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .menu-area {
    width: 100%;

    $image: (tab_icon_graph, tab_icon_transfer, tab_icon_family, tab_icon_dividend, tab_icon_money, tab_icon_siren, tab_icon_payment, tab_icon_hand, tab_icon_laborer, tab_icon_result, tab_icon_issue);

    @each $image in $image {
      li.#{$image} {
        @include sub-menu-list($image);
        width: 100%;
        padding-left: 3.4rem;
      }
    }

    @each $image in $image {
      li.#{$image}.on {
        @include sub-menu-list-on($image);
        width: 100%;
        padding-left: 3.4rem;
      }
    }
  }
}

.right-tab {
  width: 80.5rem;
  height: calc(100% - 2.5rem);
  padding: 2.5rem 4.7rem 0;

  .scroll-area {
    height: calc(100% - 2.5rem);

    & > form {
      height: 100%;
    }
  }

  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);
        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }

      &.close-btn {
        @include gray-border-btn();
      }
    }
  }
}