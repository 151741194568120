@import "../../../../scss/helpers/mixins";

.consulting-package {
  padding-top: 180px;

  picture {
    img {
      width: 100%;
    }
  }

  .img-area {
      img {
        display: none;
        width: 100%;
      }
  }

  .katalk-img-area {
    padding: 40px 0 180px;
  }

  &.info {
    padding-top: 0;

    > div {
      padding-top: 180px;
    }

    .expert-qna {
      background-color: #F9FAFB;
    }

    .online-lecture {
      background-color: #F1F6FF;
    }
  }
}

@include tablet767 {
  .consulting-package {

    picture {
      img {
        margin-right: -5.5rem;
      }
    }
  }
}

@include tablet600 {
  .consulting-package {
    padding-top: 10rem;

    &.info {

      > div {
        padding-top: 100px;
      }
    }

    .img-area {
      img {
        display: block;
      }

      > div {
        display: none;
      }
    }
  }
}

@include mobile500 {
  .consulting-package {
    picture {
      img {
        margin-right: -2.4rem;
      }
    }

    .katalk-img-area {
      margin-top: -8rem;
      padding: 0;
    }
  }
}