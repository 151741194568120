@import '../scss/themes/colors.module.scss';
@import '../scss/helpers/mixins';

@font-face {
  font-family: 'Pretendard';
  src: url('../../public/fonts/Pretendard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../../public/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../../public/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../../public/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-ExtraBold';
  src: url('../../public/fonts/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

.home_page {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Pretendard', sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #fff;

  .partners-section-inner {
    width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .partners-category {
    display: inline-block;
    margin-bottom: 40px;
    padding: 14px 20px;
    border-radius: 27px;
    background-color: $color-back;
    color: $color-main;
    font-size: 20px;
    font-weight: 700;

    &.ver2 {
      background-color: #C3D4FF;
      color: #4752e4;
    }

    &.ver3 {
      background-color: #E1EAFB;
    }

    &.ver4 {
      background-color: #D1DEFF;
    }

    &.ver5 {
      background-color: #ECF2FC;
      color: #4752E4;
    }
  }

  .partners-section-title {
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 70px;

    span {
      position: relative;
      display: inline-block;
      z-index: 1;
      color: $color-main;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 4px;
        display: inline-block;
        width: 100%;
        height: 20px;
        background-color: $color-back;
        z-index: -1;
      }

      &.ver2 {
        &::before {
          background-color: #C3D4FF;
        }
      }

      &.ver3 {
        &::before {
          background-color: #E1EAFB;
        }
      }

      &.ver4 {
        &::before {
          background-color: #D1DEFF;
        }
      }

      &.ver5 {
        &::before {
          background-color: #ECF2FC;
        }
        color: #4752E4;
      }

      &.ver6 {
        &::before {
          background-color: transparent;
        }
        color: #000;
      }

      &.no-line {
        &::before {
          display: none;
        }
      }
    }

    b {
      vertical-align: -8%;
      font-size: 100px;
      color: $color-main;
    }
  }

  .partners-sub-desc {
    font-size: 20px;
    line-height: 32px;
  }

  .partners-sub-desc2 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
  }

  .gap1 {
    padding-bottom: 72px;
  }

  .gap2 {
    padding-bottom: 40px;
  }

  .gap3 {
    padding-bottom: 180px;
  }

  .mobile-bk {
    display: none;
  }

  .lottie {
    margin: 0 auto;
    text-align: center;
  }
}

@include tablet1200 {
  .home_page {
    .partners-section-inner {
      width: 73.8rem;
    }
  }
}

//@include tablet1111 {
//  .home_page {
//    .partners-section-inner {
//      width: calc(100% - 6.0rem);
//      padding: 0 3.0rem 18.0rem;
//
//      &.img-section {
//        padding: 0;
//      }
//    }
//  }
//}

@include tablet767 {
  .home_page {
    .partners-section-inner {
      width: calc(100% - 6.0rem);
      padding: 0 3.0rem 18.0rem;

      &.img-section {
        padding: 0;
      }
    }

    .partners-category {
      margin-bottom: 3.5rem;
      padding: 1.4rem 2.0rem;
      font-size: 1.7rem;
    }

    .partners-section-title {
      font-size: 3.5rem;
      line-height: 5.5rem;

      b {
        vertical-align: -8%;
        font-size: 8.0rem;
      }
    }

    .partners-sub-desc {
      font-size: 1.7rem;
      line-height: 2.7rem;
    }

    .partners-sub-desc2 {
      font-size: 2.4rem;
      line-height: 3.84rem;
    }

    .gap1 {
      padding-bottom: 6.4rem;
    }

    .gap2 {
      padding-bottom: 3.8rem;
    }

    .gap3 {
      padding-bottom: 14.0rem;
    }
  }
}

@include mobile500 {
  .home_page {
    .partners-section-inner {
      width: 100%;
      padding: 0;
    }

    .partners-category {
      margin-bottom: 2.4rem;
      padding: .6rem 1.6rem;
      font-size: 1.8rem;
      line-height: 2.88rem;
    }

    .partners-section-title {
      font-size: 2.8rem;
      line-height: 4.5rem;

      span {
        &::before {
          bottom: .4rem;
          width: 100%;
          height: 2.0rem;
        }

        &.ver5 {
          &::before {
            background-color: transparent;
          }
        }

        &.ver6 {
          &::before {
            background-color: #ECF2FC;
          }
        }
      }

      b {
        vertical-align: -8%;
        font-size: 6.0rem;
      }
    }

    .partners-sub-desc {
      font-size: 1.7rem;
      line-height: 2.72rem;
      padding-bottom: 4rem;

      &.underBtn {
        padding-bottom: 3.2rem;
      }
    }

    .gap1 {
      padding-bottom: 5.6rem;

      &.plusGap {
        padding-bottom: 10rem;
      }
    }

    .gap2 {
      padding-bottom: 3.6rem;
    }

    .gap3 {
      padding-bottom: 10.4rem;
    }

    .mobile-bk {
      display: block;
    }

    .pc-bk {
      display: none;
    }

    .lottie {
      display: none;
    }
  }
}