@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module.scss';

.certificate-status-pop {
  width: 52rem;
  height: auto;

  .title {
    padding: 1.5rem 1.7rem;
    font-size: 1.3rem;
    font-weight: 700;
    border-bottom: .1rem solid #eee;
  }

  .certificate-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.9rem;
    width: calc(100% - 3.4rem);
    padding: 1.7rem 1.7rem 1.0rem;

    .check-box {
      @include green-chkbox();

      display: flex;
      align-items: center;
      font-size: 1.3rem;

      input[type='checkbox'] {
        margin-right: 2.3rem;
      }

      input[type='checkbox']:before {
        margin-top: -.1rem;
      }
    }
  }

  .btn-area {
    display: flex;
    justify-content: flex-end;
    column-gap: .8rem;
    padding: 0 1.7rem 2.0rem;

    .close-btn {
      @include text-btn-design(1.0rem 2.0rem);
    }

    .save-btn {
      @include primary-btn-design(1.0rem 1.9rem);

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        margin-right: .6rem;
        vertical-align: -10%;
      }
    }
  }
}