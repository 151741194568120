@import '../../../../scss/helpers/mixins';

.pricing-footer {
  width: 100%;
  background-color: #f5f5f5;
  color: #666;

  .footer-inner {
    max-width: 1004px;
    margin: 0 auto;
    padding: 40px 20px 24px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 28px;

      .logo {
        width: 125px;
        height: 26px;
      }

      .policy {
        display: flex;
        column-gap: 32px;
        line-height: 162.5%;
        font-size: 16px;
        font-weight: 600;

        a {
          color: #666;
        }
      }
    }

    .address {
      padding-bottom: 32px;
      font-size: 13px;
      font-weight: 500;

      span {
        display: inline-block;
        padding-right: 8px;
        padding-bottom: 8px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px solid rgba(153, 153, 153, 0.5);
      line-height: 138.5%;
      font-size: 13px;
      font-weight: 500;
      color: #808080;

      .sns-box {
        display: flex;
        column-gap: 16px;

        a.sns {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;

          &.insta {
            background-image: url("../../../../images/i_insta.png");
          }

          &.facebook {
            background-image: url("../../../../images/i_facebook.png");
          }

          &.appstore {
            background-image: url("../../../../images/i_app_store.png");
          }

          &.playstore {
            background-image: url("../../../../images/i_play_store.png");
          }
        }
      }
    }
  }
}

@include tablet1200 {
  .pricing-footer {

    .footer-inner {
      max-width: 100.4rem;
      padding: 4rem 2rem 2.4rem;

      .top {
        padding-bottom: 2.8rem;

        .logo {
          width: 12.5rem;
          height: 2.6rem;
        }

        .policy {
          column-gap: 3.2rem;
          font-size: 1.6rem;
        }
      }

      .address {
        padding-bottom: 3.2rem;
        font-size: 1.3rem;

        span {
          padding-right: 0.8rem;
          padding-bottom: 0.8rem;
        }
      }

      .bottom {
        padding-top: 2rem;
        font-size: 1.3rem;

        .sns-box {
          column-gap: 1.6rem;

          a.sns {
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
  }
}

@include tablet600 {
  .pricing-footer {

    .footer-inner {

      .top {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2rem;

        .policy {
          column-gap: 2.4rem;
          font-weight: 500;
        }
      }

      .bottom {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 2rem;
      }
    }
  }
}