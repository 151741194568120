@import "../../../scss/themes/colors.module";

.home-tax-page {
  width: 100%;

  .data-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 16px;
  }
}

.download-data-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25.8rem;
  padding: 2.4rem 2rem;
  border: 1px solid #eee;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.6rem;
  box-sizing: border-box;
  cursor: pointer;

  .title-area {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-start;

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
      color: #4c4c4c;

      i {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        background: url('../../../images/i_excel.png') no-repeat;
        background-size: 100% 100%;
      }
    }

    .year {
      color: #808080;
      font-size: 1.3rem;
    }
  }

  .download-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: url('../../../images/down.png') no-repeat;
    background-size: 100% 100%;
  }
}

.policy-fund-tab {
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.6rem;
    max-height: 500%;
    height: calc(100% - 20rem);
    text-align: center;

    .illust {
      width: 26.5rem;
      height: 13.5rem;
      background: url('../../../images/illust_connect.png') no-repeat;
      background-size: 100% 100%;
    }

    span.color {
      color: $color-main;
    }

    .sub-title {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 500;
    }

    .text {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #666;
    }
  }
}

@media (hover: hover) {
  .download-data-box:hover {
    background-color: #f9f9f9;
  }
}