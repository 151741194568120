@import '../../scss/helpers/mixins';

.select-box {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 100%;
  padding-left: 1.7rem;

  &.gray-box {
    display: grid;
    grid-template-columns: auto 14%;
    border-radius: .8rem;
    padding-right: 1.7rem;

    .input-txt {
      @include basic-input-box();

      width: 100%;
      padding: 1.3rem 1.8rem 1.3rem 0;
      text-align: left;
      background-color: transparent;
    }

    &:active {
      background-color: #eee;
    }

    &.error {
      border: .1rem solid #f53f50;
    }
  }

  .input-txt {
    padding-right: .5rem;
    border: none;
    text-align: right;
    font-size: 1.2rem;
    background-color: transparent;
    cursor: pointer;

    &.bold {
      font-weight: 700;
      color: #666;
    }

    &.font13 {
      font-size: 1.3rem;
    }

    &.font14 {
      font-size: 1.4rem;
      // line-height: 2.0rem;
      overflow: hidden;
      word-break: keep-all;
    }
  }

  i {
    text-align: right;
  }

  label {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url('../../images/arrow_down.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;

    &.order {
      background-image: url('../../images/order.png');
    }
  }

  .select-list {
    @include basic-shadow-box();

    position: absolute;
    right: .1rem;
    min-width: 100%;
    max-height: 30.0rem;
    padding: .8rem 0;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;
    z-index: 2;

    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  
    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      height: auto;
    }

    li {
      height: 3.6rem;
      line-height: 3.6rem;
      padding: 0 3.5rem 0 1.7rem;
      font-size: 1.3rem;
      font-weight: 400;
      cursor: pointer;

      &.selected {
        cursor: default;
      }

      &:active {
        background-color: $color-gray-f9;
      }

      span {
        display: inline-block;
        width: 1.2rem;
      }
    }
  }
}

@media (hover: hover) {
  .select-box.gray-box:hover {
    background-color: #eee;
  }

  .select-list li:hover {
    background-color: $color-gray-f9;
  }
}