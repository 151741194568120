.custom-tooltip {
  display: none;
  position: absolute;
  top: -150%;
  width: max-content;
  box-sizing: initial;
  padding: 0.9rem 1.2rem;
  text-align: center;
  font-size: 1.2rem;
  background-color: #4C4C4C;
  border-radius: 0.6rem;
  letter-spacing: -.07rem;
  color: #fff;
  z-index: 1;

  &:after {
    position: absolute;
    bottom: -1.2rem;
    content: '▼';
    font-size: 1.5rem;
    color: #4C4C4C;
    z-index: -1;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.left {
    left: -1.8rem;

    &:after {
      left: 2.7rem;
    }
  }

  &.right {
    right: -1.8rem;

    &:after {
      right: 2.7rem;
    }
  }
}