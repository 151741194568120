@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.inheritance-tax-info {
  .btn-area {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1.5rem;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -10%;
        }
      }
    }

    .add-btn {
      @include skyblue-btn(plus);

      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;
      border: .15rem solid $color-back;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }
    }
  }

  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .1rem solid #eee;
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;

    .title {
        font-size: 1.7rem;
        font-weight: 700;

        button.reset-btn {
          font-size: 1.2rem;
          color: #808080;
    
          i {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background: url('../../images/reset.png') no-repeat;
            background-size: 100% 100%;
            margin-left: 1.0rem;
            margin-right: .3rem;
            vertical-align: -10%;
          }
        }
    }

    .status-btn {
        font-size: 1.4rem;
        color: $color-main;

        i {
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            background: url('../../images/arrow3_right.png') no-repeat;
            background-size: 100% 100%;
            margin-left: .3rem;
            vertical-align: -20%;
        }
    }

  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }

    .total-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 3.7rem;
        row-gap: 2.0rem;
        padding-bottom: 5.7rem;
    }

    .sub-title {
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 2.6rem;
    }

    .sub-title-btn-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 3.4rem;
      padding-bottom: 2.6rem;

      &.stock-transfer {
        padding-top: 2rem;
        padding-right: 0;
      }

      .sub-title {
        padding-bottom: 0;
      }

      .add-btn {
        @include skyblue-btn(plus);

        margin-left: 1.2rem;
        box-sizing: border-box;
        border-radius: .6rem;
        font-size: 1.3rem;
        border: .15rem solid $color-back;

        i {
          display: inline-block;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          margin-right: .8rem;
        }
      }
    }

    .dividend-box {
        display: grid;
        grid-template-columns: 67.8rem;
        row-gap: 1.6rem;
        padding-bottom: 7.5rem;

      .check-box {
        @include green-chkbox();

        display: flex;
        align-items: center;
        margin-left: 15.5rem;
        font-size: 1.3rem;

        input:before {
          margin-top: 0.1rem;
        }
      }
    }

    .donee-box {
        position: relative;
        padding-right: 3.4rem;
        padding-bottom: 5.7rem;

        .input-area {

            .line1 {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 3.7rem;
                padding-bottom: 2.0rem;

                .select-btn {
                  width: 100%;
                }

                .select-btn-input {

                  input {
                    cursor: pointer;
                    background: #f9f9f9 url('../../images/select_box_icon.png') no-repeat;
                    background-position: 89% center;
                    background-size: 10% 30%;

                    &:read-only {
                      color: #000;
                    } 
                  }
                }
            }

            .line2 {
                display: grid;
                grid-template-columns: 37.0rem auto;
                column-gap: 8.4rem;
                padding-bottom: 2.0rem;
            }

          .line3 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding-bottom: 2.0rem;
          }
        }
    }

    .remove-btn {
      position: absolute;
      right: 0;
      top: .9rem;
      width: 2.8rem;
      height: 2.8rem;
      background: url('../../images/delete.png') no-repeat;
      background-size: 50% 50%;
      background-position: right center;
    }

    .result-table {
      width: 100%;
      border: .1rem solid #eee;
      border-radius: .8rem;
      box-sizing: border-box;

      .cell {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        width: 100%;
        height: 4.6rem;
        font-size: 1.3rem;
        align-items: center;
        text-align: center;

        &.head-box {
          background-color: #f2f2f2;
          color: #4c4c4c;
        }

        &.body-box {
          border-top: 1px solid #eee;

          .input-box {

            input {
              padding-top: 0.7rem;
              padding-bottom: 0.7rem;
              font-size: 1.3rem;
            }
          }
        }
      }
    }

    .tab-area {
      display: flex;
      column-gap: 1.5rem;
      padding-bottom: 1.5rem;
      margin-top: -1.5rem;
      margin-bottom: 2.3rem;

      button {
        padding: 1.5rem;
        border-bottom: .3rem solid #fff;
        font-size: 1.5rem;
        font-weight: 500;
        color: #999;

        &.on {
          border-bottom: .3rem solid $color-main;
          color: #000;
        }
      }
    }

    .line {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2.6rem;
      align-items: center;
      margin-bottom: 2rem;

      &.line2 {
        grid-template-columns: 1fr 1fr;
        column-gap: 10rem;
      }

      .last-line {
        font-size: 1.4rem;
        color: #4c4c4c;
      }
    }

    .noti {
      font-size: 1.2rem;
      color: $color-main;

      span {
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .transfer-design-box {
      position: relative;
      padding-right: 3.4rem;
      padding-bottom: 3rem;

      .gray input {
        color: #999;
      }

      .select-btn {
        width: 100%;

        .select-btn-input {

          input {
            cursor: pointer;
            background: #f9f9f9 url('../../images/select_box_icon.png') no-repeat;
            background-position: 89% center;
            background-size: 10% 30%;

            &:read-only {
              color: #000;
            }
          }

          &.long {
            input {
              background-position: 90% center;
              background-size: 7.5% 30%;
            }
          }
        }
      }
    }
  }
}