@import '../../scss/themes/colors.module';
@import './variables';

// 로그인 후 헤더 메뉴 ////////////////////////
@mixin menu() {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 6.4rem;
  height: 6.1rem;
  padding: 0 .4rem;
  border-radius: .8rem;
  cursor: pointer;

  i {
    display: inline-block;
    width: 2.0rem;
    height: 2.0rem;
    margin-bottom: .5rem;
  }

  span {
    font-size: 1.1rem;
  }

  &:active {
    background-color: $color-gray-f9;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-gray-f9;
    }
  }
}

@mixin header-menu($image) {
  @include menu();

  i {
    background: url('../../images/#{$image}.png') no-repeat;
    background-size: 100% 100%;
  }

  span {
    font-weight: 400;
    color: #4c4c4c;
  }
}

@mixin header-menu-disabled($image) {
  @include menu();

  cursor: default;

  i {
    background: url('../../images/#{$image}_disabled.png') no-repeat;
    background-size: 100% 100%;
  }

  span {
    font-weight: 400;
    color: #ccc;
  }

  &:active, &:hover {
    background-color: transparent;
  }
}


@mixin header-menu-on($image) {
  @include menu();

  background-color: #f5f5f5;

  &:active {
    background-color: #f5f5f5;
  }

  i {
    background: url('../../images/#{$image}_on.png') no-repeat;
    background-size: 100% 100%;
  }

  span {
    font-weight: 700;
    color: $color-main;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

///////////////////////////////////////////////////////////

// 서브 메뉴 공통 ////////////////////////////////////////////
@mixin sub-menu() {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 5.2rem;
  column-gap: 1.5rem;
  padding-left: 2.3rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s linear;

  &:active {
    background-color: $color-gray-f9;
  }

  i {
    display: inline-block;
    width: 2.0rem;
    min-width: 2.0rem;
    height: 2.0rem;
  }

  span {
    font-size: 1.3rem;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-gray-f9;
    }
  }
}

@mixin sub-menu-list($image) {
  @include sub-menu();

  i {
    background: url('../../images/#{$image}.png') no-repeat;
    background-size: 100% 100%;
  }

  span {
    font-weight: 400;
    color: $color-gray-4c;
    white-space: nowrap;
  }

  &:active {
    background-color: #fbfbfb;
  }

  @media (hover: hover) {
    &:hover{
      background-color: #fbfbfb;
    }
  }
}

@mixin sub-menu-list-on($image) {
  @include sub-menu();
  background-color: #f5f5f5;

  &:active {
    background-color: #f5f5f5;
  }

  i {
    background: url('../../images/#{$image}_on.png') no-repeat;
    background-size: 100% 100%;
  }

  span {
    font-weight: 700;
    color: $color-main;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

///////////////////////////////////////////////////////////

@mixin basic-shadow-box() {
  border-radius: .8rem;
  background-color: #fff;
  //box-shadow: 0 .5rem 1.0rem rgba(0, 0, 0, 0.12);
  box-shadow: 0 .5rem 1.0rem hsl(0deg 0% 0% / 12%);
}

@mixin gray-border-btn() {
  box-sizing: border-box;
  padding: 1.0rem 1.9rem;
  border: .2rem solid #eee;
  color: $color-gray-4c;
  border-radius: .6rem;
  font-size: 1.3rem;

  &:active {
    background-color: #f9f9f9;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #f9f9f9;
    }
  }
}

@mixin primary-border-btn() {
  box-sizing: border-box;
  padding: 1.0rem 1.9rem;
  border: .1rem solid $color-main;
  color: $color-main;
  background-color: #fff;
  border-radius: .6rem;
  font-size: 1.3rem;

  &:active {
    background-color: $color-back-hover;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-back-hover;
    }
  }
}

@mixin skyblue-btn($image:null) {
  padding: 1.0rem 1.7rem;
  border-radius: .6rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: $color-main;
  background-color: $color-back;

  &:active {
    background-color: $color-back-hover;
  }

  @if $image != null {
    i {
      display: inline-block;
      width: 1.0rem;
      height: 1.0rem;
      margin-right: .8rem;
      background: url('../../images/#{$image}.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-back-hover;
    }
  }
}

@mixin primary-btn($image:null) {
  padding: 1.0rem 1.7rem;
  border-radius: .6rem;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
  background-color: $color-main;

  &:active {
    background-color: $color-main-hover;
  }

  &:disabled {
    background-color: $color-back2;
  }

  @if $image != null {
    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: .8rem;
      background: url('../../images/#{$image}.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-main-hover;

      &:disabled {
        background-color: $color-back2;
      }
    }
  }
}

@mixin basic-table-body($gridColumns, $gap) {
  display: grid;
  align-items: center;
  grid-template-columns: $gridColumns;
  width: calc(100% - #{$gap + $gap}px);
  height: 5.3rem;
  padding: 0 #{$gap}px;
  text-align: left;
  font-size: 1.3rem;
  cursor: pointer;

  &:active {
    background-color: $color-gray-f9;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .txt-left {
    text-align: left;
  }

  .txt-center {
    text-align: center;
  }

  .txt-right {
    text-align: right;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-gray-f9;
    }
  }
}

@mixin basic-table-head($gridColumns, $gap) {
  display: grid;
  align-items: center;
  grid-template-columns: $gridColumns;
  width: calc(100% - #{$gap + $gap}px);
  height: 4.6rem;
  padding: 0 #{$gap}px;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  border-top: .1rem solid #eee;
  border-bottom: .1rem solid #eee;
  color: #4c4c4c;
  background-color: #fcfcfc;

  span:first-child {
    text-align: left;
  }

  span:last-child {
    text-align: right;
  }

  .txt-left {
    text-align: left;
  }

  .txt-center {
    text-align: center;
  }

  .txt-right {
    text-align: right;
  }
}

//green checkbox
@mixin green-chkbox() {
  position: relative;
  cursor: pointer;

  input[type='checkbox'],
  input[type='radio'] {
    cursor: pointer;
    width: 0;
    margin-right: 2.0rem;
    appearance: none;
    height: 1.6rem;
  }

  input[type='checkbox']:before,
  input[type='radio']:before {
    content: "";
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-size: 100% 100%;
    background-image: url('../../images/chk_box.png');
  }
  input[type='checkbox']:checked:before,
  input[type='radio']:checked:before {
    background-image: url('../../images/chk_box_on.png');
  }

  &:active > input[type='checkbox']:before,
  &:active > input[type='radio']:before {
    border-radius: .4rem;
    background-color: #eee;
  }

  &.partial input[type='checkbox']:before,
  &.partial input[type='radio']:before {
    background-image: url('../../images/chk_box_partial.png');
  }

  input[type='checkbox']:disabled:before,
  input[type='radio']:disabled:before {
    background-image: url('../../images/chk_box_disabled.png');
  }

  @media (hover: hover) {
    &:hover > input[type='checkbox']:before,
    &:hover > input[type='radio']:before {
      border-radius: .4rem;
      background-color: #eee;
    }
  }
}

//blue checkbox
@mixin blue-chkbox() {
  position: relative;
  cursor: pointer;

  input[type='checkbox'],
  input[type='radio'] {
    cursor: pointer;
    width: 0;
    margin-right: 2.0rem;
    appearance: none;
    height: 2rem;
  }

  input[type='checkbox']:before,
  input[type='radio']:before {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    background-size: 100% 100%;
    background-image: url('../../images/blue_chk_btn.png');
  }
  input[type='checkbox']:checked:before,
  input[type='radio']:checked:before {
    background-image: url('../../images/blue_chk_btn_on.png');
  }

  &:active > input[type='checkbox']:before,
  &:active > input[type='radio']:before {
    border-radius: 50%;
    background-color: #eee;
  }

  input[type='checkbox']:disabled:before,
  input[type='radio']:disabled:before {
    background-image: url('../../images/blue_chk_btn_disabled.png');
  }

  @media (hover: hover) {
    &:hover > input[type='checkbox']:before,
    &:hover > input[type='radio']:before {
      border-radius: 50%;
      background-color: #f9f9f9;
    }
  }
}

//blue checkbox2
@mixin blue-chkbox2() {
  position: relative;
  cursor: pointer;

  input[type='checkbox'],
  input[type='radio'] {
    cursor: pointer;
    width: 0;
    margin-right: 2.0rem;
    appearance: none;
    height: 1.6rem;
  }

  input[type='checkbox']:before,
  input[type='radio']:before {
    content: "";
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    background-size: 100% 100%;
    background-image: url('../../images/chk_box.png');
  }
  input[type='checkbox']:checked:before,
  input[type='radio']:checked:before {
    background-image: url('../../images/chk_box_blue_on.png');
  }

  &:active > input[type='checkbox']:before,
  &:active > input[type='radio']:before {
    border-radius: .4rem;
    background-color: #eee;
  }

  input[type='checkbox']:disabled:before,
  input[type='radio']:disabled:before {
    background-image: url('../../images/chk_box_disabled2.png');
  }

  input[type='checkbox']:disabled:checked:before,
  input[type='radio']:disabled:checked:before {
    background-image: url('../../images/chk_box_disabled.png');
  }

  @media (hover: hover) {
    &:hover > input[type='checkbox']:before,
    &:hover > input[type='radio']:before {
      border-radius: .4rem;
      background-color: #eee;
    }
  }
}

//main color radio circle
@mixin main-radio-box() {
  position: relative;
  display: inline-block;
  cursor: pointer;

  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
    width: 0;
    height: 100%;
    margin-right: 2.4rem;
  }

  input[type='radio']:before,
  input[type='checkbox']:before {
    content: "";
    display: block;
    width: 2.0rem;
    height: 2.0rem;
    background-image: url('../../images/radio.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  input[type='radio']:checked:before,
  input[type='checkbox']:checked:before {
    background-image: url('../../images/radio_on.png');
  }

  input[type='radio']:disabled,
  input[type='checkbox']:disabled{
    cursor: default;
  }

  input[type='radio']:disabled:before,
  input[type='checkbox']:disabled:before {
    background-image: url('../../images/radio_disabled.png');
  }
}

@mixin radio-box-ver2() {
  position: relative;
  display: inline-block;
  cursor: pointer;

  input[type='radio'],
  input[type='checkbox'] {
    cursor: pointer;
    width: 0;
    height: 100%;
    margin-right: 2.4rem;
  }

  input[type='radio']:before,
  input[type='checkbox']:before {
    content: "";
    display: block;
    width: 2.0rem;
    height: 2.0rem;
    background-image: url('../../images/i_check.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  input[type='radio']:checked:before,
  input[type='checkbox']:checked:before {
    background-image: url('../../images/i_check_on.png');
  }
}

@mixin basic-input-box-bg() {
  border: none;
  border-radius: .8rem;
  background-color: #f9f9f9;
  outline-color: $color-main;
}

//basic-input-box
@mixin basic-input-box() {
  @include basic-input-box-bg();
  padding: 1.3rem 1.8rem;
}

//basic-input-box2
@mixin basic-input-box2() {
  padding: 0 1.2rem;
  border: .1rem solid #ccc;
  border-radius: .6rem;
  box-sizing: border-box;

  &:focus {
    border: .1rem solid $color-main;
  }

  &:disabled {
    background-color: $color-gray-f9 !important;
    color: #9c9c9c;
  }

  &.error {
    border: .1rem solid #f53f50;
  }
}

//select-input-box
@mixin select-input-box($backgroundSize,$image:select_box_icon, $positionX:96%) {
  padding: 1.3rem 3.2rem 1.3rem 1.8rem;
  border: none;
  border-radius: .8rem;
  background: #f9f9f9 url('../../images/#{$image}.png') no-repeat;
  background-position: $positionX center;
  background-size: $backgroundSize;
}

@mixin select-input-box2($backgroundSize, $position) {
  padding: 1.3rem 3.2rem 1.3rem 1.8rem;
  border-radius: .8rem;
  border: .1rem solid #ccc;
  background: url('../../images/select_box_icon.png') no-repeat;
  background-position: $position center;
  background-size: $backgroundSize;

  &.error {
    border: .1rem solid #f53f50;
  }
}

// edit-btn
@mixin btn20($image) {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60% 60%;
  background-image: url('../../images/#{$image}.png');
  cursor: pointer;

  &:active {
    background-color: #f5f5f5;
  }

  &:active[disabled] {
    background-color: initial;
  }

  @media (hover: hover) {
    &:hover{
      background-color: #f5f5f5;
    }

    &:hover[disabled]{
      background-color: initial;
    }
  }
}

// main-color-btn
@mixin primary-btn-design($padding) {
  padding: $padding;
  border-radius: .6rem;
  background-color: $color-main;
  color: #fff;

  &:active {
    background-color: $color-main-hover;
  }

  &:disabled {
    background-color: $color-sky-blue2;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-main-hover;

      &:disabled {
        background-color: $color-sky-blue2;
        cursor: default;
      }
    }
  }
}

// 배경, 보더 없는 버튼
@mixin text-btn-design($padding) {
  padding: $padding;
  border-radius: .6rem;

  &:active {
    background-color: $color-gray-f9;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $color-gray-f9;
    }
  }
}

//hover color만 필요한 경우
@mixin background-color-hover($backColor, $hoverColor) {
  background-color: $backColor;

  &:active {
    background-color: $hoverColor;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $hoverColor;
    }
  }
}

@mixin date-box($width, $backgroundSize, $positionX) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    display: inline-block;
    // margin-right: 5.1rem;
    font-size: 1.4rem;
    color: #4c4c4c;
  }

  & > div {
    width: auto;

    input {
      @include basic-input-box();

      width: $width;

      background-image: url('../../images/calendar.png');
      background-repeat: no-repeat;
      background-size: $backgroundSize;
      background-position: $positionX center;
      cursor: pointer;
    }
  }

  :global(.error-message) {
    margin-top: 5.4rem;
  }
}

@mixin keyword($color) {
  display: inline-block;
  padding: 0.05rem 0.9rem;
  line-height: 1.9rem;
  font-size: 1.1rem;
  border-radius: 5rem;

  @if $color == gray {
    color: #4c4c4c;
    background-color: #eee;
  } @else if $color == blue {
    color: $color-main;
    background-color: #ECF2FC;
  }
}

@mixin icon($width, $height, $icon) {
  display: inline-block;
  width: $width;
  height: $height;
  background-image: url('../../images/#{$icon}.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@mixin animate($animation,$duration,$method,$times){
  animation: $animation $duration $method $times;
}

@mixin keyframes($name){
  @keyframes #{$name}{
    @content;
  }
}

//미디어쿼리
@mixin tablet1200 {
  @media (max-width: #{$breakpoint-tablet1200}) {
    @content;
  }
}

@mixin tablet1111 {
  @media (max-width: #{$breakpoint-tablet1111}) {
    @content;
  }
}

@mixin tablet1000 {
  @media (max-width: #{$breakpoint-tablet1000}) {
    @content;
  }
}

@mixin tablet880 {
  @media (max-width: #{$breakpoint-tablet880}) {
    @content;
  }
}

@mixin tablet767 {
  @media (max-width: #{$breakpoint-tablet767}) {
    @content;
  }
}

@mixin tablet700 {
  @media (max-width: #{$breakpoint-tablet700}) {
    @content;
  }
}

@mixin tablet600 {
  @media (max-width: #{$breakpoint-tablet600}) {
    @content;
  }
}

@mixin mobile500 {
  @media (max-width: #{$breakpoint-mobile500}) {
    @content;
  }
}

@mixin mobile417 {
  @media (max-width: #{$breakpoint-mobile417}) {
    @content;
  }
}

@mixin mobile360 {
  @media (max-width: #{$breakpoint-mobile360}) {
    @content;
  }
}

@mixin mobile320 {
  @media (max-width: #{$breakpoint-mobile320}) {
    @content;
  }
}