@import '../../../scss/helpers/mixins';
@import '../../../scss/themes/colors.module';

.edit-info {
  position: absolute;
  right: 0;
  top: 0;
  width: 80.5rem;
  height: calc(100% - 5.0rem);
  padding: 2.5rem 4.7rem;
  border-left: .1rem solid #eee;
  background-color: #fff;
  border-bottom-right-radius: .8rem;
  border-top-right-radius: .8rem;

  form {
    height: 100%;
  }

  .button-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.0rem;
    padding-bottom: 1.5rem;

    .back {
      padding: .8rem 1.0rem;
      border-radius: .8rem;
      font-size: 1.4rem;

      &:active {
        background-color: #f9f9f9;
      }

      i {
        display: inline-block;
        width: .8rem;
        height: 1.5rem;
        margin-right: 1.4rem;
        background: url('../../../images/back_btn.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -10%;
      }
    }

    .save {
      @include primary-btn-design(1.0rem 1.9rem);

      font-size: 1.3rem;
      font-weight: 700;
      color: #fff;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: .4rem;
        background: url('../../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        vertical-align: -13%;
      }
    }

    .add-btn {
      @include skyblue-btn(plus);

      margin-right: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;
      border: .15rem solid $color-back;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }
    }
  }

  .input-area {
    height: calc(100% - 4.0rem);
    overflow: hidden;
    overflow-y: auto;

    .scroll {
      padding-top: 4.0rem;
      padding-bottom: 2.6rem;
    }

    .title {
      padding-bottom: 4.0rem;
      font-size: 1.7rem;
      font-weight: 700;
    }

    .sub-title {
      padding: 2.0rem 0 2.6rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .form-box {

    .input-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.6rem;

      > label {
        width: 10.6rem;
        padding-top: 1.2rem;
        line-height: 2.4rem;
        font-size: 1.3rem;
        color: #666;
        align-self: flex-start;
      }

      &.error {
        input {
          border: .1rem solid #f53f50;
        }
      }

      input {
        @include basic-input-box();

        font-size: 1.4rem;

        &.select-pop {
          width: 46.2rem;
          padding-right: 4.0rem;
          text-overflow: ellipsis;
          cursor: pointer;

          &:active {
            background-color: #eee;
          }

          background-image: url('../../../images/select_box_icon.png');
          background-position: 96.5% center;
          background-size: 3% 35%;
          background-repeat: no-repeat;
        }

        &:disabled {
          color: #666;
          cursor: default;
        }
      }

      textarea {
        @include basic-input-box();

        resize: none;
        width: 48.4rem;
        min-height: 14.6rem;
        outline: none;
      }

      &.date-box {

        input {
          @include select-input-box(2.8% 33%, calendar);

          padding-right: 1.8rem;
        }
      }
    }

    .fam-box {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;

      .line {
        display: flex;
        column-gap: 1.6rem;
      }

      textarea {
        min-height: 8.8rem;
      }

      .date-box2 {

        button.cal-btn {
          @include select-input-box(5.5% 29%, calendar, 92%);
        }
      }

      .fam-select-box {
        input {
          padding-left: 0;
          background-color: transparent;
        }
      }

      .delete-btn {
        position: absolute;
        right: -4rem;
        top: .85rem;
        width: 3.0rem;
        height: 3.0rem;
        border-radius: 100%;
        background-image: url('../../../images/delete.png');
        background-size: 40% 40%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &:active {
          background-color: #f9f9f9;
        }
      }
    }

    .issue-box {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 1.6rem;

      .line {
        display: flex;
        column-gap: 1.6rem;
      }

      textarea {
        min-height: 8.8rem;
      }

      .delete-btn {
        position: absolute;
        right: 14rem;
        top: .85rem;
        width: 3.0rem;
        height: 3.0rem;
        border-radius: 100%;
        background-image: url('../../../images/delete.png');
        background-size: 40% 40%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &:active {
          background-color: #f9f9f9;
        }
      }
    }

    //공통 셀렉트 인풋 박스 커스텀 (edit-common에서 가져와서 쓰는 인풋박스)
    .select-input-box {
      justify-content: flex-start;
      margin-bottom: 1.6rem;

      > label {
        width: 10.6rem;
        font-size: 1.3rem;
        color: #666;
      }

      &.no-data-first-list {
        ul li:first-child {
          display: none;
        }
      }
    }

    .birthday-box {
      display: flex;
      justify-content: flex-start;

      .input-box {
        width: 50.0rem;

        input[type='text'] {
          @include select-input-box(3.7% 34.1%, calendar);

          width: 34.0rem;
          cursor: pointer;
        }
      }

      .calendars-box {
        display: inline-block;
        padding-top: 1.3rem;

        .green-chk-box {
          @include green-chkbox();

          margin-right: .6rem;
          margin-left: 1.1rem;
        }

        span {
          vertical-align: 20%;
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media (hover: hover) {
  .button-area .back:hover {
    background-color: #f9f9f9;
  }

  .select-pop:hover {
    background-color: #eee;
  }

  .edit-info .form-box .fam-box .delete-btn:hover {
    background-color: #f9f9f9;
  }
}