@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module';

.review {
  padding: 180px 0;
  background-color: #e1edff;

  .mobile-bk {
    display: none;
  }

  .review-all {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 64px;
    padding: 12px 16px;
    line-height: 26px;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    background-color: $color-main;

    span {
      font-weight: 700;
    }
  }

  .grid-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 24px;
    align-items: center;
  }

  .katalk-img {
    max-width: 643px;
    width: 100%;
  }
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 56px);
  padding: 28px 40px;
  border-radius: 16px;
  background-color: #fff;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    padding-bottom: 12px;
    white-space: pre-wrap;
  }

  .star {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    padding-bottom: 24px;
    font-size: 16px;

    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &.blue {
      span {
        background-image: url('../../../../images/partners_home/star_blue_empty.png');

        &.full {
          background-image: url('../../../../images/partners_home/star_blue_full.png');
        }

        &.half {
          background-image: url('../../../../images/partners_home/star_blue_half.png');
        }
      }
    }

    &.orange {
      span {
        background-image: url('../../../../images/partners_home/star_orange_empty.png');

        &.full {
          background-image: url('../../../../images/partners_home/star_orange_full.png');
        }

        &.half {
          background-image: url('../../../../images/partners_home/star_orange_half.png');
        }
      }
    }

    &.green {
      span {
        background-image: url('../../../../images/partners_home/star_green_empty.png');

        &.full {
          background-image: url('../../../../images/partners_home/star_green_full.png');
        }

        &.half {
          background-image: url('../../../../images/partners_home/star_green_half.png');
        }
      }
    }

    &.yellow {
      span {
        background-image: url('../../../../images/partners_home/star_yellow_empty.png');

        &.full {
          background-image: url('../../../../images/partners_home/star_yellow_full.png');
        }

        &.half {
          background-image: url('../../../../images/partners_home/star_yellow_half.png');
        }
      }
    }
  }

  .customer-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px 8px 8px;
    border-radius: 30px;
    margin-bottom: 24px;
    font-size: 20px;

    &.blue {
      background-color: #E1EDFF;
    }

    &.orange {
      background-color: $color-orange-light;
    }


    &.green {
      background-color: $color-green-back;
    }


    &.yellow {
      background-color: $color-yellow-back;
    }

    .cus-img {
      display: inline-block;
      width: 36px;
      height: 36px;
      margin-right: 12px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 50%;

      &.blue {
        background-color: $color-main;
      }

      &.orange {
        background-color: #FF835B;
      }


      &.green {
        background-color: #5CBF39;
      }


      &.yellow {
        background-color: #FFD600;
      }
    }

    .txt-area {
      display: inline-flex;
    }

    .bold {
      padding-right: 5px;
      font-weight: 700;
    }
  }

  .txt {
    text-align: left;
    font-size: 20px;
    line-height: 32px;

    &.txt-control {
      flex: 1;
      min-height: 192px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }

    span.bold {
      font-weight: 700;
    }
  }

  .date {
    width: 100%;
    padding-top: 12px;
    line-height: 28px;
    text-align: right;
    font-size: 16px;
    color: #808080;
  }
}

@include tablet1200 {
  .review {
    padding: 18rem 0;

    .review-all {
      margin-top: 4rem;
      margin-bottom: 6.4rem;
      padding: 1.2rem 1.6rem;
      line-height: 2.6rem;
      border-radius: .8rem;
      font-size: 1.8rem;
    }

    .grid-box {
      column-gap: 2.4rem;
      row-gap: 2.4rem;
    }
  }

  .review-box {
    padding: 2.8rem 4rem;
    border-radius: 1.6rem;

    .title {
      font-size: 2.4rem;
      line-height: 3.4rem;
      padding-bottom: 1.2rem;
    }

    .star {
      column-gap: .4rem;
      padding-bottom: 2.4rem;
      font-size: 1.6rem;

      span {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    .customer-info {
      padding: .8rem 2rem .8rem .8rem;
      border-radius: 3rem;
      margin-bottom: 2.4rem;
      font-size: 2rem;

      .cus-img {
        width: 3.6rem;
        height: 3.6rem;
        margin-right: 1.2rem;
      }

      .txt-area {
        flex-direction: column;
        row-gap: 10px;
      }

      .bold {
        padding-right: .5rem;
      }
    }

    .txt {
      font-size: 2rem;
      line-height: 3.2rem;

      &.txt-control {
        min-height: 19.2rem;
      }
    }

    .date {
      padding-top: 1.2rem;
      line-height: 2.8rem;
      font-size: 1.6rem;
    }
  }
}

@include tablet1111 () {
  .review {
    padding: 18rem 0;
  }
  .review-box {

    .title {

      &.titleH {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6.2rem;
      }
    }

    .customer-info {
      padding: 1.1rem 2.3rem 1.1rem 1.1rem;
      border-radius: 4.2rem;

      .txt-area {
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }
}

@include tablet880() {
  .review-box {
    .title {
      font-size: 2.2rem;
    }

    .txt {
      &.txt-control {
        min-height: 28.8rem;
        -webkit-line-clamp: 9;
      }
    }
  }
}

@include tablet767() {
  .review {
    padding: 18rem 0 0;

    .grid-box {
      grid-template-columns: 1fr;
    }
  }

  .review-box {
    .title {

      &.titleH {
        height: auto;
      }
    }

    .txt {
      &.txt-control {
        min-height: auto;
        -webkit-line-clamp: 10;
      }
    }
  }
}

@include tablet600() {
  .review {

    .mobile-bk {
      display: block;
    }

    .review-all {
      margin-top: 2rem;
      margin-bottom: 5.6rem;
    }
  }
}

@include mobile500() {
  .review {
    padding: 10rem 2.4rem 8.1rem;

    .sub-desc {
      padding-bottom: 3.2rem;
    }

    .review-all {
      margin-top: 0;
    }
  }

  .review-box {
    padding: 4rem 2.4rem 2.7rem;

    .customer-info {
      border-radius: 3.4rem;
    }
  }
}

@include mobile417() {
  .review-box {
    //.title {
    //  font-size: 1.9rem;
    //}

    .customer-info {
      padding: 0.8rem 2rem 0.8rem 0.8rem;
      line-height: 2.72rem;
      font-size: 1.7rem;

      .cus-img {
        width: 27px;
        height: 27px;
      }

      .txt-area {
        flex-direction: column;
        row-gap: 0;
      }
    }

    .txt {
      font-size: 1.7rem;
      line-height: 2.72rem;
    }

    .date {
      font-size: 13px;
    }
  }
}