.product-list-pop {
  position: relative;
  width: 60.8rem;
  height: auto;
  padding: 3.0rem 3.4rem 4.8rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: .8rem;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent #d9d9d9;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: .2rem;
  }

  .title-line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .title {
      padding-bottom: 4.0rem;
      font-size: 2.0rem;
      font-weight: 700;
    }

    .payment-system-table {
      padding-top: 0.5rem;
      font-size: 1.2rem;
      text-decoration: underline;
      color: #5c67ff;

      i {
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url('../../images/arrow3_right.png');
        vertical-align: -30%;
      }
    }
  }

  .grid-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.2rem;
    row-gap: 1.4rem;
  }
}