@import "../../scss/helpers/mixins";

.provisional-payment {
  width: 100%;

  .title {
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;

    button.reset-btn {
      font-size: 1.2rem;
      color: #808080;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/reset.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 1.0rem;
        margin-right: .3rem;
        vertical-align: -10%;
      }
    }
  }

  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }

      &.close-btn {
        @include gray-border-btn();
      }
    }
  }

  .grid-box {
    width: calc(100% - 5rem);
    display: grid;
    grid-template-columns: 1fr repeat(3, 16rem);
    column-gap: 3.2rem;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: #4c4c4c;

    .inner-grid-box {
      display: grid;
      grid-template-rows: 3.0rem repeat(5, 4.6rem);
      align-items: center;
      justify-content: flex-start;
      row-gap: 1.6rem;

      &.box2 {

      }

      .year-txt {
        text-align: center;
      }

      .select-box2 {
        input {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }

  .grid-box2 {
    width: calc(100% - 5rem);
    display: grid;
    grid-template-columns: 1fr repeat(3, 16rem);
    column-gap: 3.2rem;
    align-items: center;
    margin: 1.6rem 0 3.2rem;
    font-size: 1.4rem;
    color: #4c4c4c;
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }
}