@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.share-customer-pop {
  display: flex;
  flex-direction: column;
  width: 51.2rem;
  height: 41.6rem;
  box-sizing: border-box;
  padding: 3rem 3.4rem 2rem;
  color: #4c4c4c;

  .title {
    padding-bottom: 1.6rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.8rem;
  }

  .desc {
    padding-bottom: 3.2rem;
    line-height: 2rem;
    font-size: 1.3rem;
  }

  .search-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search-input {
      flex: 1;
      display: flex;
      align-items: center;
      height: 4.6rem;
      background: #F9F9F9;
      border-radius: 8px;

      input {
        flex: 1;
        border: none;
        background-color: transparent;
        text-indent: 1.8rem;
        font-size: 1.4rem;
      }

      .reset-txt-btn {
        width: 4.0rem;
        height: 6.1rem;
        background: url('../../images/delete2.png') no-repeat;
        background-position: 50% 50%;
        background-size: 40%;
      }
    }

    .search-result-box {
      @include basic-shadow-box();

      position: absolute;
      left: 0;
      top: 100%;
      width: 40rem;
      max-height: 16rem;
      overflow: hidden;
      overflow-y: auto;

      ul.scroll {
        padding: 0.8rem 0;

        li {
          display: flex;
          align-items: center;
          column-gap: 6.4rem;
          width: 100%;
          height: 3.6rem;
          box-sizing: border-box;
          padding: 0 1.8rem;
          font-size: 1.3rem;
          cursor: pointer;

          &:active {
            background-color: #fbfbfb;
          }
        }
      }
    }

    .search-btn {
      width: 4.0rem;
      height: 6.1rem;
      background: url('../../images/icon_search2.png') no-repeat;
      background-position: 50% 50%;
      background-size: 100%;
    }
  }

  .space {
    flex: 1;
    font-size: 1.4rem;

    .no-data {
      display: flex;
      justify-content: center;
      padding-top: 2.8rem;
      color: #999;
    }

    .search-result {
      display: flex;
      align-items: center;
      padding: 2.8rem 0;
      color: #000;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/i_blue_chk2.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 1.2rem;
      }

      p {
        padding-right: 6.4rem;
      }
    }
  }
}

.btn-area {
  display: flex;
  justify-content: space-between;

  .share-btn {
    @include gray-border-btn();

    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background: url('../../images/i_share2.png') no-repeat;
      background-size: 100% 100%;
      margin-right: .5rem;
      vertical-align: -8%;
    }
  }

  .right-btn-area {
    display: flex;
    justify-content: flex-end;
    column-gap: 1.0rem;

    .close-btn {
      @include text-btn-design(1.0rem 2.0rem);
    }

    .save-btn {
      @include primary-btn-design(1.0rem 2.0rem);

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        margin-right: .5rem;
        vertical-align: -10%;
      }
    }
  }
}

@media (hover:hover) {
  .share-customer-pop {
    .search-box {
      .search-result-box {
        ul.scroll {
          li {
            &:hover {
              background-color: #fbfbfb;
            }
          }
        }
      }
    }
  }
}