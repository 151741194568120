@import '../../scss/themes/colors.module';
@import '../../scss/helpers/mixins';

.select-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  .btn-st1 {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2.1rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &.move-group {
      background-image: url('../../images/switch.png');
    }

    &:active {
      div {
        display: block;
      }
    }
  }

  .width100 {
    width: 100%;
  }

  .border-bottom {
    flex: 1;
  }

  .border-bottom + ul li.title {
    height: 4.0rem;
    margin-bottom: .4rem;
    border-bottom: .1rem solid #eee;
  }

  .select-list {
    @include basic-shadow-box();

    position: absolute;
    right: 0;
    max-height: 30.0rem;
    padding: .8rem 0;
    background-color: #fff;
    z-index: 2;

    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;

    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      height: 3.6rem;
      line-height: 3.6rem;
      padding-left: 1.7rem;
      font-size: 1.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &.title {
        font-weight: 700;
        cursor: default;
      }

      &.add-list {
        color: $color-main;
        font-weight: 700;
      }

      &:active:not(.title) {
        background-color: $color-gray-f9;
      }
    }
  }
}

@media (hover: hover) {
  .btn-st1:hover div {
    display: block;
  }

  .select-list li:hover:not(.title) {
    background-color: $color-gray-f9;
  }
}