@import '../../../../scss/helpers/mixins';
@import '../../../../scss/themes/colors.module.scss';

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: calc(100% - 60px);
  height: 66px;
  padding: 0 30px;
  z-index: 10;

  &.white {
    background-color: #fff;
    border-bottom: 1px solid #eee;
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1004px;
    height: 100%;
    margin: 0 auto;

    h1.logo {
      width: 53px;
      height: 100%;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }
    }

    .nav-flex-box {
      display: flex;
      padding-left: 2rem;
      font-size: 15px;

      .mobile-nav-header {
        display: none;
      }

      .nav-bar {
        display: flex;
        align-items: center;
        font-weight: 500;

        li {
          padding: 0 6px;
          white-space: nowrap;

          &.katalk-btn {
            a {
              display: flex;
              align-items: center;
              column-gap: 0.2rem;
              padding: 0;

              i {
                display: inline-block;
                width: 2.1rem;
                height: 2.1rem;
                background: url('../../../../images/i_kakao2.png') no-repeat;
                background-size: 100% 100%;
              }
            }

          }

          a {
            display: inline-block;
            padding: 10px 12px 11px;

            .icon-new {
              display: inline-block;
              width: 18px;
              height: 18px;
              background-image: url('../../../../images/icon_new.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-left: 3px;
              vertical-align: -25%;
            }
          }

          &.mobile-menu {
            display: none;
          }
        }
      }

      .btn-area {
        // display: none;
        display: flex;
        column-gap: 6px;
        margin-left: 6px;

        .login-btn {
          //@include gray-border-btn();
          padding: 10px 12px 11px;
          //background-color: #fff;
          font-size: 15px;
          font-weight: 500;
          color: #4752E4;
          white-space: nowrap;
          //border-radius: 8px;
        }
      }

      .promotion-btn-area {
        margin-left: 10px;

        .promotion-btn {
          display: flex;
          align-items: center;
          padding: 10px 16px 10px 14px;
          background-color: #FF7F64;
          font-weight: 700;
          font-size: 15px;
          border-radius: 8px;
          color: #fff;

          i {
            display: inline-block;
            width: 23px;
            height: 21px;
            background: url('../../../../images/partners_home/icon_bell.png') no-repeat;
            background-size: 100% 100%;
            margin-right: 5px;
            margin-bottom: -3px;
          }
        }
      }
    }

    .mobile-btn {
      display: none;
      width: 48px;
      height: 48px;
      background: url('../../../../images/partners_home/mobile_btn.png') no-repeat;
      background-size: 50% 38%;
      background-position: right 50%;
    }
  }
}

@include tablet1111 {
  .header {
    .header-inner {
      .nav-flex-box {
        .btn-area {
          margin-left: 1.5rem;
        }
      }
    }
  }
}



@media all and (max-width: 767px) {
  .header {
    width: calc(100% - 4.0rem);
    height: 6.8rem;
    padding: 0 2.0rem;
    background-color: #fff;

    .header-inner {

      h1.logo {
        width: 4.8rem;

        img {
          width: 100%;
        }
      }

      .nav-flex-box {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;

        &.show {
          display: block;
        }

        .btn-area {
          display: none;
        }

        .promotion-btn-area {
          display: none;
        }

        .nav-bar {
          display: block;
          font-weight: 400;
          background-color: #fff;
          box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);

          li {
            padding: 0;

            &.katalk-btn {
              padding: 10px 12px 11px;
            }

            a {
              display: inline-block;
              width: calc(100% - 4.0rem);
              padding: 1.8rem 2.0rem;

              &.orange {
                color:#FF7F64;
                font-weight: 700;
              }
            }

            &.mobile-menu {
              display: block;
            }

            &:active {
              background-color: #efefef;
            }

            &.mobile-nav-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: calc(100% - 4.0rem);
              height: 6.8rem;
              padding: 0 2.0rem;
              background-color: #fff;

              .logo {
                width: 4.8rem;

                img {
                  width: 100%;
                  margin-bottom: -.2rem;
                }
              }

              .close-btn {
                width: 4.8rem;
                height: 4.8rem;
                background: url('../../../../images/partners_home/close_btn.png') no-repeat;
                background-position: right 50%;
                background-size: 50% 50%;
              }
            }
          }
        }
      }

      .mobile-btn {
        display: block;
      }
    }
  }
}

@include tablet600 {
  .header {
    width: calc(100% - 4.0rem);
    height: 6.8rem;
    padding: 0 2.0rem;
    background-color: #fff;

    &.white {
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(8px);
      border-bottom: none;
    }

    .header-inner {

      h1.logo {
        width: 4.8rem;

        img {
          width: 100%;
        }
      }

      .nav-flex-box {
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;

        &.show {
          display: block;
        }

        .btn-area {
          display: none;
        }

        .promotion-btn-area {
          display: none;
        }

        .nav-bar {
          display: block;
          font-weight: 400;
          background-color: #fff;
          box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);

          li {
            padding: 0;

            a {
              display: inline-block;
              width: calc(100% - 4.0rem);
              padding: 1.8rem 2.0rem;

              &.orange {
                color:#FF7F64;
                font-weight: 700;
              }
            }

            &.mobile-menu {
              display: block;
            }

            &:active {
              background-color: #efefef;
            }

            &.mobile-nav-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: calc(100% - 4.0rem);
              height: 6.8rem;
              padding: 0 2.0rem;
              background-color: #fff;

              .logo {
                width: 4.8rem;

                img {
                  width: 100%;
                  margin-bottom: -.2rem;
                }
              }

              .close-btn {
                width: 4.8rem;
                height: 4.8rem;
                background: url('../../../../images/partners_home/close_btn.png') no-repeat;
                background-position: right 50%;
                background-size: 50% 50%;
              }
            }
          }
        }
      }

      .mobile-btn {
        display: block;
      }
    }
  }
}