@import '../../scss/helpers/mixins';
@import '../../scss/themes/colors.module';

.noti-bot-pop {
  //width: 72.2rem;
  //height: 63.9rem;
  position: relative;
  width: 112.3rem;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  //padding: 2.5rem 3.4rem 2rem;

  .top-line {
    //padding-bottom: 2.4rem;
    padding: 2.5rem 3.4rem 0;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;

    .flex-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4rem;
      padding-bottom: 1.5rem;

      .title {
        max-width: 57.2rem;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.8rem;
      }

      .close-btn {
        @include gray-border-btn();
      }
    }

    .content-info {
      display: flex;
      column-gap: 4rem;
      font-size: 1.3rem;
      line-height: 2rem;
      color: #4c4c4c;

      p {
        display: flex;
        column-gap: 0.8rem;
      }
    }
  }

  .iframe-box {
    width: 100%;
    height: calc(100% - 6rem);
    height: calc(100% - 8.07rem);
  }

  .scroll-area {
    width: 100%;
    height: calc(100% - 11.3rem);
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    box-sizing: border-box;
    padding-top: 3.2rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .scroll-box {
      min-height: 100%;
    }
  }
}
