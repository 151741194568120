@import '../../../scss/helpers/mixins';

.personal-data {
  width: 100%;

  .table {
    margin-bottom: 3.0rem;
  }

  .line-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 3rem;
    //min-height: 5.0rem;

    .one-set {
      display: flex;
      width: 36.5rem;
      line-height: 2.0rem;
      font-size: 1.3rem;

      .title {
        display: inline-block;
        width: 13.0rem;
        color: #666;
      }

      .desc {
        width: 23.5rem;
        line-height: 2.2rem;
        white-space: pre-wrap;
      }

      &.two {
        width: 100%;

        .fam {
          flex: 1;

          .fam-list {
            display: flex;
            justify-content: space-between;
            column-gap: 1.6rem;
            padding-bottom: 0.2rem;

            span {
              display: inline-block;
              width: 7.2rem;

              &:nth-child(2) {
                width: 5.7rem;
              }

              &:nth-child(3) {
                width: 13.3rem;
              }

              &:last-child {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}

@media (hover: hover) {
  .personal-data {
    .line-box {
      .one-set {
        .fam {
          .fam-list {
            .tooltip {
              button:hover {
                background-color: #f9f9f9;
              }
            }
          }
        }
      }
    }
  }
}