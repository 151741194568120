@import '../../../../scss/themes/colors.module.scss';
@import '../../../../scss/helpers/mixins';

.co-report-section {
  width: 100%;
  padding-top: 180px;
  background-color: #F9FAFB;

  .video-box {
    width: 120%;
    margin-left: -10%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin-bottom: 180px;

    &.katalk-video-box {
      margin-bottom: 80px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .color-box {
    width: 100%;
    padding-top: 80px;
    text-align: center;
    border-radius: 16px;
    overflow: hidden;

    &.blue {
      background-color: $color-back;
      margin-bottom: 32px;
      padding-bottom: 80px;

      .sub-title {
        padding-bottom: 24px;
      }

      .txt-area {
        width: 75%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .column-txt {
          display: flex;
          flex-direction: column;
          align-items: center;

          .mob-br {
            display: none;
          }

          p {
            width: 216px;
            font-size: 24px;
            line-height: 150%;
            font-weight: 700;
          }

          span {
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
          }

          i {
            width: 19px;
            height: 19px;
            background-image: url('../../../../images/partners_home/i_add.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin: 4px 0;
          }
        }
      }
    }

    &.orange {
      background-color: $color-orange-light;
      margin-bottom: 32px;

      .sub-title {
        position: relative;
        z-index: 1;
        padding-bottom: 24px;
      }

      .lottie-cover-box {
        margin-top: -40px;
      }
    }

    &.green {
      background-color: $color-green-back;
      padding-bottom: 88px;
      margin-bottom: 32px;

      .sub-title {
        padding-bottom: 72px;
      }

      .report-cate-img {
        width: 81%;
      }
    }

    &.purple {
      background-color: $color-purple-back;
      padding-bottom: 88px;

      .sub-title {
        padding-bottom: 72px;
      }

      .report-cate-img {
        width: 81%;
      }
    }

    .box-title {
      padding-bottom: 32px;
      font-size: 36px;
      font-weight: 700;
      line-height: 48px;
    }

    .sub-title {
      font-size: 20px;
      line-height: 30px;

      b {
        font-weight: 700;
      }

      .icon_c {
        font-size: 17px;
      }
    }

    .chg-img {
      display: none;
      margin: 0 auto;

      &.blue-box-img {
        width: 85%;
        margin-top: 40px;
      }

      &.orange-box-img {
        width: 100%;
      }
    }
  }

  .service-guide {
    @include primary-btn(arrow_right_wh);

    padding-right: 6px;
    font-weight: 400;

    span {
      font-weight: 700;
    }

    i {
      margin-left: 5px;
      vertical-align: -14%;
    }
  }
}

@include tablet1200 {

  .co-report-section {
    .video-box {
      padding-bottom: 60.25%;
    }

    .color-box {
      &.blue {
        .txt-area {
          width: 95%;

          .column-txt {
            flex: 1;

            p {
              width: auto;
              font-size: 2.2rem;
            }
          }
        }
      }

      &.green {
        padding-bottom: 80px;

        .sub-title {
          padding-bottom: 72px;
        }

        .report-cate-img {
          width: 90%;
        }
      }
    }
  }
}

@include tablet767 {
  .co-report-section {
    padding-top: 10.0rem;

    .color-box {
      &.blue {
        .txt-area {
          .column-txt {
            .mob-br {
              display: block;
            }

            p {
              font-size: 2rem;
            }

            span {
              font-size: 1.5rem;
            }
          }
        }
      }

      &.orange {
        .lottie-cover-box {
          margin-top: -2.0rem;
        }
      }

      &.green {
        padding-bottom: 6.0rem;

        .report-cate-img {
          width: 85%;
        }
      }

      .box-title {
        font-size: 3.0rem;
        line-height: 4.0rem;
      }

      .sub-title {
        font-size: 1.7rem;
        line-height: 2.8rem;

        .icon_c {
          font-size: 1.4rem;
        }
      }
    }
  }
}

@include mobile500 {
  .co-report-section {
    padding-top: 10.0rem;

    div.inner-gap3 {
      padding-bottom: 0;
    }

    .video-box {
      margin-bottom: 10rem;

      &.katalk-video-box {
        margin-bottom: 8rem;
      }
    }

    .color-box {
      border-radius: 0;

      &.blue {
        padding-bottom: 8.0rem;
        margin-bottom: 0;

        .sub-title {
          padding-bottom: 0;
        }

        .txt-area {
          display: none;
        }
      }

      &.orange {
        margin-bottom: 0;

        .lottie-cover-box {
          margin-top: 0.8rem;
        }
      }

      &.green {
        padding-bottom: 8.0rem;
        margin-bottom: 0;

        .sub-title {
          padding-bottom: 4rem;
        }

        .report-cate-img {
          width: 85%;
        }
      }

      .box-title {
        font-size: 2.4rem;
        line-height: 3.84rem;
        padding-bottom: 3rem;
      }

      .sub-title {
        font-size: 1.5rem;
        line-height: 2.4rem;

        .icon_c {
          font-size: 1.2rem;
        }
      }

      .chg-img {
        display: block;
      }
    }

  }
}