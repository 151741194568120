.fee-policy-pop {
  width: 52.7rem;

  .title-box {
    padding: 3rem 3.4rem 2rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.8rem;
    color: #333;
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
    padding: 0 3.4rem 1.2rem;

    .top {
      font-size: 1.3rem;
      line-height: 2rem;

      span {
        font-weight: 700;
      }
    }

    .bulb-title {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
      color: #5c67ff;
    }

    .table {
      width: 100%;
      font-size: 1.3rem;
      line-height: 2rem;

      thead {
        height: 3rem;
        font-weight: 700;
        background-color: #f9f9f9;
        border-top: 0.1rem solid #eee;
        border-bottom: 0.1rem solid #eee;
        color: #4c4c4c;

        tr {
          padding: 0 2.4rem;
        }

        tr th {
          &:first-child {
            text-align: left;
            padding-left: 2.4rem;
          }

          &:last-child {
            padding-right: 2.4rem;
          }

          vertical-align: middle;
          white-space: nowrap;

          span {
            padding-left: 0.4rem;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.9rem;
            color: #4c4c4c;
          }
        }
      }

      tbody {

        tr {
          height: 3rem;
          border-bottom: 0.1rem solid #eee;

          td {
            &:first-child {
              padding-left: 2.4rem;
            }

            &:last-child {
              text-align: center;
              padding-right: 2.4rem;
            }

            vertical-align: middle;
            white-space: nowrap;

            span {
              padding-left: 0.4rem;
              font-size: 1.2rem;
              font-weight: 400;
              line-height: 1.9rem;
              color: #4c4c4c;
            }
          }
        }
      }
    }

    .example {
      display: flex;
      align-items: flex-start;
      column-gap: 0.8rem;
      font-size: 1.3rem;
      line-height: 2rem;
      color: #666;

      p:first-child {
        font-weight: 500;
        color: #FF835B;
        white-space: nowrap;
      }
    }

    .how-to-do {

      .list {
        &.info {
          font-size: 1.2rem;
          color: #666;
        }

        display: grid;
        grid-template-columns: 1.4rem auto;
        font-size: 1.3rem;
        line-height: 2rem;
        color: #333;

        .link {
          display: inline-block;
          font-size: 1.2rem;
          color: #5c67ff;
          line-height: 2rem;
          text-decoration: underline;
          white-space: nowrap;
        }
      }
    }
  }

  .btn-area {
    display: flex;
    justify-content: flex-end;
    padding: 0.8rem 3.4rem 2rem;

    button {
      border: none;
    }
  }
}