@import '../../scss/helpers/mixins';

.emergency-funds-design {
  width: 100%;

  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }
    }
  }

  .title {
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;
  }

  .sub-title {
    font-size: 1.5rem;
    font-weight: 500;
    padding-bottom: 2.6rem;

    &.pt {
      padding-top: 4.8rem;
    }
  }

  .input-area {
    width: 47.4rem;

    .input-box {
      margin-bottom: 1.6rem;
    }
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }
}