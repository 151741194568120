@import '../../scss/helpers/mixins';

.purchase-materials-pop {
  position: relative;
  width: 44.8rem;
  //min-height:66.3rem;
  padding: 3.0rem 3.4rem 2.0rem;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: .8rem;
  overflow-y: auto;

  .title {
    padding-bottom: 4.0rem;
    font-size: 2.0rem;
    font-weight: 700;
  }

  .sub-title {
    padding: 3.2rem 0 1.1rem;
    font-size: 1.6rem;
    font-weight: 700;
  }

  .blue-box {
    position: relative;
    width: 100%;
    border: .1rem solid $color-main;
    border-radius: .8rem;
    box-sizing: border-box;

    .box-title {
      width: 100%;
      height: 5.2rem;
      line-height: 5.2rem;
      border-radius: .8rem .8rem 0 0;
      border-bottom: .1rem solid $color-main;
      text-indent: 1.6rem;
      font-size: 1.6rem;
      font-weight: 700;
      box-sizing: border-box;
      color: $color-main;
      background-color: $color-back;
    }

    .flex-box {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1.6rem;
      font-size: 1.3rem;

      .label {
        font-weight: 700;
      }
    }

    .first-box {
      padding-top: 1.6rem;
      padding-bottom: 0.5rem;
      margin: 0 1.6rem;
      border-bottom: .1rem solid #eee;

      input {
        display: none;
      }
    }

    .second-box {
      padding: 1.6rem 1.6rem .5rem;

      .total-amt {
        input {
          display: none;
        }
      }
    }
  }

  .input-area {

    .input-box {
      margin-bottom: 1.3rem;
    }

    .address-box {
      display: flex;
      justify-content: flex-end;
      column-gap: 1.3rem;

      .chg-address {
        @include gray-border-btn();

        margin-bottom: 1.3rem;
        border-width: 0.1rem;
      }
    }

    .add-shipping-address {
      display: flex;
      align-items: center;

      p {
        width: 7.4rem;
        font-size: 1.3rem;
      }

      .add-address {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.8rem;
        height: 4.6rem;
        border: 0.1rem solid #eee;
        border-radius: 0.8rem;
        color: $color-main;

        &:active {
          background-color: $color-sky-blue;
        }

        i {
          display: inline-block;
          width: 2rem;
          height: 2rem;
          background: url('../../images/i_add.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }

  .btn-area {
    //position: absolute;
    //left: 0;
    //bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 2rem;
    column-gap: .8rem;
    box-sizing: border-box;

    .close-btn {
      @include text-btn-design(1.0rem 2.0rem);
    }

    .payment-btn {
      @include primary-btn-design(1.0rem 1.9rem);

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/save_chk_btn.png') no-repeat;
        background-size: 100% 100%;
        margin-right: .6rem;
        vertical-align: -10%;
      }
    }
  }
}

@media (hover:hover) {
  .purchase-materials-pop {
    .input-area {
      .add-shipping-address {
        .add-address:hover {
          background-color: $color-sky-blue;
        }
      }
    }
  }
}

@media (max-width: 766px) {
  //.payment-system-table {
  //  display: none;
  //}
}