@import '../../scss/helpers/mixins';

.salary-info {
  width: 100%;

  .btn-area {
    padding-bottom: 1.5rem;
    text-align: right;

    button {
      margin-left: 1.2rem;
      box-sizing: border-box;
      border-radius: .6rem;
      font-size: 1.3rem;

      i {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: .8rem;
      }

      &.save-btn {
        @include primary-btn-design(1.0rem 1.9rem);

        font-weight: 700;

        i {
          width: 1.4rem;
          height: 1.4rem;
          background-image: url('../../images/save_chk_btn.png');
          vertical-align: -14%;
        }
      }
    }
  }

  .title {
    padding-bottom: 1.1rem;
    margin-bottom: 2.7rem;
    border-bottom: .1rem solid #eee;
    font-size: 1.7rem;
    font-weight: 700;

    button.reset-btn {
      font-size: 1.2rem;
      color: #808080;

      i {
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        background: url('../../images/reset.png') no-repeat;
        background-size: 100% 100%;
        margin-left: 1.0rem;
        margin-right: .3rem;
        vertical-align: -10%;
      }
    }
  }

  .input-area {
    width: 67.8rem;
    padding-bottom: 2.6rem;

    .tab-area {
      display: flex;
      column-gap: 1.5rem;
      padding-bottom: 1.5rem;
      margin-top: -1.5rem;
      margin-bottom: 1.6rem;

      button {
        padding: 1.5rem;
        border-bottom: .3rem solid #fff;
        font-size: 1.5rem;
        font-weight: 500;
        color: #999;

        &.on {
          border-bottom: .3rem solid $color-main;
          color: #000;
        }
      }
    }

    .select-btn {
      width: 100%;
    }

    .input-box {
      margin-bottom: 1.6rem;

      &.esp-box {
        margin-bottom: 0;
      }

      &.select-box {

        input:read-only {
          @include select-input-box(2.8% 36%, $positionX: 96.5%);

          cursor: pointer;
          color: #000;
        }
      }

      &.date-box {
        @include date-box(48.0rem, 2.8% 33%, 96.5%);
      }
    }

    // // 특정 inputbox 색깔 검은색으로 고정
    .input-box-color {
        input {
          color: black !important;
        }
    }

    .sub-title {
      padding-bottom: 2.4rem;
      font-size: 1.5rem;
      font-weight: 500;

      &.title2 {
        padding-top: 3.7rem;
      }
    }

    .severance-pay-info {
      display: flex;
      // column-gap: 7.0rem;
      justify-content: space-between;
      width: 100%;
      font-size: 1.4rem;
      color: #4c4c4c;

      .option-title {
        min-width: 9.6rem;
        padding-top: 1.4rem;
      }

      & > ul > li {
        width: 100%;
        padding-top: 1.2rem;

        .radio-btn {
          @include main-radio-box();

          display: flex;
          align-items: center;
          column-gap: .6rem;
          height: 2.0rem;
          padding-bottom: 1.1rem;
        }

        .check-box {
          @include green-chkbox();

          display: inline-block;
          padding-left: 3.0rem;
          padding-bottom: 1.2rem;

          input::before {
            margin-top: .6rem;
          }
        }
      }
    }
  }

  .scroll-box {
    height: calc(100% - 5.8rem);

    .scroll-area {
      height: calc(100% - 3.5rem);
      padding-top: 3.5rem;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      white-space: pre-wrap; //react-pdf는 기본값이 pre-wrap임
    }

    .scroll-area::-webkit-scrollbar {
      display: none;
    }
  }

  .table {
    width: 100%;
    border: 1px solid #eee;
    border-radius: 0.8rem;
    font-size: 1.3rem;

    .head {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 4.6rem;
      background-color: #f2f2f2;

      p {
        text-align: center;

        &:first-child {
          flex: 1;
        }

        &:last-child {
          flex: 1.5;
        }
      }
    }

    .body {
      width: 100%;

      .flex-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 4.6rem;
        padding: 0 2.4rem;
        box-sizing: border-box;

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }

        .mix {
          display: flex;
          align-items: center;

          span.plus {
            display: inline-block;
            padding-left: 1rem;
            color: #999;
          }

          span.salary {
            display: inline-block;
            min-width: 9.3rem;
            text-align: right;
          }
        }

        .table-input-box {

          label {
            padding-right: 2rem;
            font-size: 1.3rem;
            color: #000;
          }

          input {
            height: 3.4rem;
          }
        }
      }
    }
  }
}

.multiple-option {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 1.1rem;
  padding-left: 3.5rem;
  padding-bottom: 1.6rem;
  box-sizing: border-box;

  .first-input-box {
    width: 20.5rem;
  }
}