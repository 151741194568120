@import "../../../../scss/helpers/mixins";

.failure-history-pop {
  @include basic-shadow-box();

  display: flex;
  flex-direction: column;
  width: 44.8rem;
  max-height: 66.3rem;
  padding: 0 3.4rem;
  box-sizing: border-box;

  .title {
    padding: 3rem 0 2rem;
    font-size: 2rem;
    font-weight: 700;
    color: #333;
  }

  .info {
    padding-bottom: 2rem;
    line-height: 2.2rem;
    font-size: 1.6rem;

    .sub-title {
      padding-right: 0.4rem;
      font-weight: 500;
    }

    .red {
      color: #F53F50;
    }
  }

  .list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.8rem;
    color: #333;
    font-size: 1.5rem;
    overflow: hidden;

    .head {
      display: grid;
      grid-template-columns: 2.6rem auto;
      column-gap: 6rem;
      padding: 1.3rem 3rem 1.3rem 2rem;
      background-color: #FCFCFC;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      font-weight: 500;
      border-bottom: 1px solid #eee;
    }

    .body {
      flex: 1;
      overflow: hidden;
      overflow-y: auto;

      .list-one {
        display: grid;
        grid-template-columns: 2.6rem auto;
        align-items: center;
        column-gap: 2.4rem;
        padding: 1.2rem 3rem 1.3rem 2rem;

        .name {
          display: flex;
          align-items: center;
          column-gap: 0.8rem;

          img {
            display: inline-block;
            width: 2.8rem;
            height: 2.8rem;
            background-color: #d9d9d9;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border-radius: 50%;
          }

          span {
            display: inline-block;
            flex: 1;
            width: 24.4rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .btn-area {
    display: flex;
    justify-content: flex-end;
    padding: 0.8rem 0 2rem;

    .close-btn {
      border: none;
    }
  }
}