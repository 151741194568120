@import "../../scss/helpers/mixins";

.info {
  margin-top: -1rem;
  padding-bottom: 2.7rem;
  font-size: 1.3rem;
  color: #808080;
}

.chk-box-area {
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  .blue-chkbox {
    @include blue-chkbox2();

    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    font-size: 1.4rem;
  }
}