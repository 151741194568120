@import '../../scss/helpers/mixins';

.payment-window-pop {
    position: relative;
    width: 44.8rem;
    //min-height:66.3rem;
    padding: 3.0rem 3.4rem 2.0rem;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: .8rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent #d9d9d9;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d9d9d9;
        border-radius: .2rem;
    }

    &.choose-pay-card-pop {
        padding: 3.0rem 3.4rem 6.0rem;
    }

    &.pass-type {
        //min-height: auto;
        height: auto;
    }

    .title {
        padding-bottom: 4.0rem;
        font-size: 2.0rem;
        font-weight: 700;
    }

    .sub-title {
        font-size: 1.6rem;
        font-weight: 700;
    }

    .title-line {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .payment-system-table {
            padding-top: 0.5rem;
            font-size: 1.2rem;
            text-decoration: underline;
            color: #5c67ff;

            i {
                display: inline-block;
                width: 1.8rem;
                height: 1.8rem;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-image: url('../../images/arrow3_right.png');
                vertical-align: -30%;
            }
        }
    }

    .blue-box {
        position: relative;
        width: 100%;
        height: 49.4rem;
        //margin-bottom: 6rem;
        border: .1rem solid $color-main;
        border-radius: .8rem;
        box-sizing: border-box;

        .box-title {
            width: 100%;
            height: 5.2rem;
            line-height: 5.2rem;
            border-radius: .8rem .8rem 0 0;
            border-bottom: .1rem solid $color-main;
            text-indent: 1.6rem;
            font-size: 1.6rem;
            font-weight: 700;
            box-sizing: border-box;
            color: $color-main;
            background-color: $color-back;
        }

        .flex-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            font-size: 1.3rem;

            .label {
                font-weight: 700;
            }
        }

        .first-box {
            padding-top: 1.6rem;
            margin: 0 1.6rem;
            border-bottom: .1rem solid #eee;

            .discount-coupon-btn {
                display: inline-block;
                padding: .1rem .4rem .1rem 1.0rem;
                margin-left: .6rem;
                border-radius: 1.0rem;
                color: #fff;
                background-color: $color-main;
                font-size: 1.1rem;

                i {
                    display: inline-block;
                    width: 1.6rem;
                    height: 1.6rem;
                    background: url('../../images/arrow_right_wh.png') no-repeat;
                    background-size: 100% 100%;
                    vertical-align: -25%;
                }
            }

            input {
                display: none;
            }
        }

        .second-box {
            padding: 1.6rem 1.6rem .5rem;

            .total-amt {
                input {
                    display: none;
                }
            }

            .caution {
                padding: 1.0rem 0 2.8rem;
                color: #808080;
                font-size: 1.2rem;

                .caution-title {
                    padding-bottom: .4rem;
                    font-weight: 700;
                }

                ul li {
                    position: relative;
                    padding-left: .8rem;
                    line-height: 1.9rem;

                    &::before {
                        position: absolute;
                        left: -.2rem;
                        top: 0;
                        font-weight: 700;
                        font-size: 1.4rem;
                        content: '∙';
                    }
                }
            }

            .method-area {
                display: flex;
                column-gap: 2.0rem;
            }
        }

        .bottom-line {
            position: absolute;
            bottom: 2rem;
            left: 1.6rem;

            .chk-box {
                @include green-chkbox();

                display: flex;
                align-items: center;
                font-size: 1.2rem;
                line-height: 1.9rem;
            }
        }
    }

    .radio-btn {
        @include main-radio-box();

        display: flex;
        align-items: center;
        height: 1.7rem;

        input[type='radio']:before {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    .radio-txt {
        padding: 1.6rem 0;
        font-size: 1.3rem;
        font-weight: 700;

        &.bd-bt {
            border-bottom: 0.1rem solid #eee;
        }

        .radio-btn {
            height: 1.7rem;
        }
    }

    .product-box {
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 7rem;
        padding: 1.1rem 1.6rem;
        margin-top: 1.2rem;
        margin-bottom: 3rem;
        background-color: #f9f9f9;
        border-radius: 0.8rem;
        box-sizing: border-box;
        font-size: 1.3rem;

        .bold {
            text-align: right;
            font-weight: 700;
        }
    }

    .add-card-area {
        width: calc(100% - 2.5rem);
        padding-left: 2.5rem;
        padding-bottom: 4rem;
        border-bottom: 0.1rem solid #eeeeee;

        &.no-left-padding {
            width: calc(100%);
            padding-left: 0;
        }

        .regi-card-list {
            width: 100%;
            max-height: 19.2rem;
            overflow: hidden;
            overflow-y: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .card-box {
            display: flex;
            align-items: center;
            width: 100%;
            height: 5rem;
            box-sizing: border-box;
            margin-bottom: 1.2rem;
            border: 0.1rem solid #eee;
            border-radius: 0.8rem;
            font-size: 1.3rem;
            cursor: pointer;

            &.add-btn {
                justify-content: center;
                column-gap: 0.8rem;
                color: #5c67ff;

                i {
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background: url('../../images/i_add.png') no-repeat;
                    background-size: 100% 100%;
                }
            }

            &.card-registered {
                justify-content: space-between;
                padding: 0 1.6rem;

                &.on {
                    background-color: $color-sky-blue;
                    border: 1px solid $color-main;
                }
            }
        }

        .noti {
            text-align: center;
            font-size: 1.2rem;
            color: #808080;
        }
    }

    .deposit {
        padding-left: 3rem;

        .input-box {
            margin-bottom: 1.3rem;

            label {
                font-size: 1.3rem;
                color: #000;
            }

            input {
                font-size: 1.3rem;
            }
        }

        .receipt {
            display: flex;
            column-gap: 1rem;
            padding: 1.2rem 0 2.4rem;

            .receipt-title {
                font-size: 1.3rem;
            }

            label {
                font-size: 1.3rem;
            }
        }

        .input-num-area {
            padding-left: 7.4rem;
        }
    }

    .btn-area {
        //position: absolute;
        //left: 0;
        //bottom: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-top: 2rem;
        column-gap: .8rem;
        box-sizing: border-box;

        .close-btn {
          @include text-btn-design(1.0rem 2.0rem);
        }

        .payment-btn {
          @include primary-btn-design(1.0rem 1.9rem);

          i {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background: url('../../images/save_chk_btn.png') no-repeat;
            background-size: 100% 100%;
            margin-right: .6rem;
            vertical-align: -10%;
          }
        }
    }

    .dialog-background {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        border-radius: .8rem;

        .discount-coupon-pop {
            width: 32.8rem;
            top: calc(50% - 10.6rem);
            left: calc(50% - 19.8rem);
            padding: 3.2rem 3.4rem 3.0rem;
            text-align: left;

            .input-box {
                padding-top: .5rem;

                ul li:first-child {
                    display: none;
                }
            }

            .btn-area {
                justify-content: center;
                padding-top: 1.4rem;
            }
        }
    }
}

@media (hover:hover) {
    .payment-window-pop {
        .add-card-area {
            .card-box {
                &:hover {
                    background-color: $color-sky-blue;
                }
            }
        }
    }
}

@media (max-width: 766px) {
    .payment-system-table {
        display: none;
    }
}

.purchase-materials-pop {
    position: relative;
    width: 44.8rem;
    //min-height:66.3rem;
    padding: 3.0rem 3.4rem 2.0rem;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: .8rem;
    overflow-y: auto;

    .title {
        padding-bottom: 4.0rem;
        font-size: 2.0rem;
        font-weight: 700;
    }

    .sub-title {
        padding: 3.2rem 0 1.1rem;
        font-size: 1.6rem;
        font-weight: 700;
    }

    .blue-box {
        position: relative;
        width: 100%;
        border: .1rem solid $color-main;
        border-radius: .8rem;
        box-sizing: border-box;

        .box-title {
            width: 100%;
            height: 5.2rem;
            line-height: 5.2rem;
            border-radius: .8rem .8rem 0 0;
            border-bottom: .1rem solid $color-main;
            text-indent: 1.6rem;
            font-size: 1.6rem;
            font-weight: 700;
            box-sizing: border-box;
            color: $color-main;
            background-color: $color-back;
        }

        .flex-box {
            display: flex;
            justify-content: space-between;
            padding-bottom: 1.6rem;
            font-size: 1.3rem;

            .label {
                font-weight: 700;
            }
        }

        .first-box {
            padding-top: 1.6rem;
            padding-bottom: 0.5rem;
            margin: 0 1.6rem;
            border-bottom: .1rem solid #eee;

            input {
                display: none;
            }
        }

        .second-box {
            padding: 1.6rem 1.6rem .5rem;

            .total-amt {
                input {
                    display: none;
                }
            }
        }
    }

    .input-area {
        padding-bottom: 6.9rem;

        .input-box {
            margin-bottom: 1.3rem;
        }

        .address-box {
            display: flex;
            justify-content: flex-end;
            column-gap: 1.3rem;

            .chg-address {
                @include gray-border-btn();

                margin-bottom: 1.3rem;
                border-width: 0.1rem;
            }
        }

        .add-shipping-address {
            display: flex;
            align-items: center;

            p {
                width: 7.4rem;
                font-size: 1.3rem;
            }

            .add-address {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 0.8rem;
                height: 4.6rem;
                border: 0.1rem solid #eee;
                border-radius: 0.8rem;
                color: $color-main;

                &:active {
                    background-color: $color-sky-blue;
                }

                i {
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background: url('../../images/i_add.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }

    .btn-area {
        //position: absolute;
        //left: 0;
        //bottom: 0;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-top: 2rem;
        column-gap: .8rem;
        box-sizing: border-box;

        .close-btn {
            @include text-btn-design(1.0rem 2.0rem);
        }

        .payment-btn {
            @include primary-btn-design(1.0rem 1.9rem);

            i {
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                background: url('../../images/save_chk_btn.png') no-repeat;
                background-size: 100% 100%;
                margin-right: .6rem;
                vertical-align: -10%;
            }
        }
    }
}

@media (hover:hover) {
    .purchase-materials-pop {
        .input-area {
            .add-shipping-address {
                .add-address:hover {
                    background-color: $color-sky-blue;
                }
            }
        }
    }
}

@media (max-width: 766px) {
    //.payment-system-table {
    //  display: none;
    //}
}