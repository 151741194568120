@import '../../scss/helpers/mixins';

.select-box {
  position: relative;
  width: auto;
  height: 100%;
  line-height: 100%;
  box-sizing: border-box;

  input {
    border: none;
    border-radius: .6rem;
    padding: 1rem 3rem 1rem 1.5rem;
    background-color: #f9f9f9;
    font-size: 1.4rem;
    font-weight: 700;
    cursor: pointer;
  }

  i.arrow {
    position: absolute;
    right: 1.2rem;
    top: calc(50% - 0.75rem);
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('../../images/arrow_down.png') no-repeat;
    background-size: 100% 100%;
  }

  .select-list {
    @include basic-shadow-box();

    position: absolute;
    right: .1rem;
    min-width: 100%;
    max-height: 30.0rem;
    padding: .8rem 0;
    overflow: hidden;
    overflow-y: auto;
    white-space: nowrap;
    z-index: 2;

    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ul {
      height: auto;
    }

    li {
      height: 3.6rem;
      line-height: 3.6rem;
      padding: 0 3.5rem 0 1.7rem;
      font-size: 1.3rem;
      font-weight: 400;
      cursor: pointer;

      &.selected {
        cursor: default;
      }

      &:active {
        background-color: $color-gray-f9;
      }

      span {
        display: inline-block;
        width: 1.2rem;
      }
    }
  }
}

@media (hover: hover) {
  .select-box input:hover {
    background-color: #efefef;
  }

  .select-list li:hover {
    background-color: $color-gray-f9;
  }
}