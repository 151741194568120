@import '../../scss/helpers/mixins';

.edit-stage-list-pop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  
    .stage-list-title {
      padding: 1.5rem 1.7rem;
      font-size: 1.3rem;
      font-weight: 700;
      border-bottom: .1rem solid #eee;
    }

    .input-only {
      padding: 1.5rem 1.7rem;

      input {
        width: 100%;
        padding: 1.1rem 1.8rem;
        border: .1rem solid #eee;
        box-sizing: border-box;
        border-radius: .8rem;
        font-size: 1.3rem;
      }
    }
  
    .stage-input-list {
      padding: 1.3rem 1.7rem .9rem;
  
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: .5rem;
        padding-bottom: 1.3rem;
  
        // &:not(:last-child) {
        //   padding-bottom: 1.3rem;
        // }
  
        // &:last-child button {
        //   width: calc(100% - 3.6rem);
        //   padding: 1.1rem 1.8rem;
        //   border: .1rem solid $color-back;
        //   background-color: $color-back;
        //   color: $color-main;
        //   border-radius: .8rem;
        //   font-size: 1.3rem;
        //   font-weight: 700;
        //   text-align: left;
        // }
  
        input {
          width: calc(100% - 3.6rem);
          padding: 1.1rem 1.8rem;
          border: .1rem solid #eee;
          box-sizing: border-box;
          border-radius: .8rem;
          font-size: 1.3rem;
        }
  
        .delete-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3.0rem;
          height: 3.0rem;
          border-radius: 50%;
          background-image: url('../../images/delete.png');
          background-size: 45% 45%;
          background-position: 50% 50%;
          background-repeat: no-repeat;
  
          &:active {
            background-color: #f9f9f9;
          }
        }
      }

      &.has-unit li input {
        background-image: url(../../images/icon_percent.png);
        background-repeat: no-repeat;
        background-size: 10% 33%;
        background-position: 93% center;
      }
    }

    .btn-area {
      display: flex;
      justify-content: space-between;
      column-gap: 1.0rem;
      padding: 0 1.7rem 1.9rem;
    
      .add-btn {
        @include primary-btn-design(1.0rem 1.8rem);
    
        background-color: #fff;
        color: $color-main;

        &:active {
          background-color: $color-sky-blue;
        }

        i {
          display: inline-block;
          width: 1.1rem;
          height: 1.1rem;
          background: url('../../images/plus.png') no-repeat;
          background-size: 100% 100%;
          margin-right: .5rem;
          // vertical-align: -10%;
        }
      }
    
      .save-btn {
        @include primary-btn-design(1.0rem 2.0rem);
    
        font-weight: 700;

        i {
          display: inline-block;
          width: 1.4rem;
          height: 1.4rem;
          background: url('../../images/save_chk_btn.png') no-repeat;
          background-size: 100% 100%;
          margin-right: .5rem;
          vertical-align: -10%;
        }
      }
    }     
  }

  @media (hover: hover) {
    .edit-stage-list-pop .stage-input-list li .delete-btn:hover {
      background-color: #f9f9f9;
    }

    .edit-stage-list-pop .btn-area .add-btn:hover {
      background-color: $color-sky-blue;
    }
  }