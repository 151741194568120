@import "../../../scss/helpers/mixins";

.edit-consult-log {

  .edit-dialog {
    position: absolute;
    width: 51.8rem;
    padding: 3.0rem 3.4rem 2.0rem;

    .title {
      padding-bottom: 4.1rem;
      font-size: 2.0rem;
      font-weight: 700;
    }

    .input-box {
      display: grid;
      grid-template-columns: 10.6rem auto;
      align-items: center;
      padding-bottom: 1.6rem;

      label {
        display: inline-block;
        font-size: 1.4rem;
        color: #4c4c4c;
      }

      .inner-input {
        position: relative;
      }

      input {
        @include select-input-box(4% 40%);

        box-sizing: border-box;
        width: 100%;
        cursor: pointer;
      }

      textarea {
        @include basic-input-box();

        width: 37.6rem;
        min-height: 14.6rem;
        resize: none;
      }

      &.date-box {
        input {
          @include select-input-box(3.5% 34%, calendar);
        }
      }
    }
  }
}

.btn-area {
  display: flex;
  justify-content: flex-end;
  column-gap: 1.0rem;

  .close-btn {
    @include text-btn-design(1.0rem 2.0rem);
  }

  .save-btn {
    @include primary-btn-design(1.0rem 2.0rem);

    i {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background: url('../../../images/save_chk_btn.png') no-repeat;
      background-size: 100% 100%;
      margin-right: .5rem;
      vertical-align: -10%;
    }
  }
}

.edit-stage-list-pop {
  @include basic-shadow-box();

  position: absolute;
  top: 9.0rem;
  right: -28.0rem;
  width: 30.0rem;

  .stage-list-title {
    padding: 1.5rem 1.7rem;
    font-size: 1.3rem;
    font-weight: 700;
    border-bottom: .1rem solid #eee;
  }

  .stage-input-list {
    max-height: 22rem;
    padding: 1.3rem 1.7rem 2.2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        padding-bottom: 1.3rem;
      }

      &:last-child button {
        width: 23.3rem;
        padding: 1.1rem 1.8rem;
        border: .1rem solid $color-back;
        background-color: $color-back;
        color: $color-main;
        border-radius: .8rem;
        font-size: 1.3rem;
        font-weight: 700;
        text-align: left;
      }

      input {
        width: 19.5rem;
        padding: 1.1rem 1.8rem;
        border: .1rem solid #eee;
        border-radius: .8rem;
        font-size: 1.3rem;
      }

      .delete-btn {
        width: 3.0rem;
        height: 3.0rem;
        border-radius: 100%;
        background-image: url('../../../images/delete.png');
        background-size: 40% 40%;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &:active {
          background-color: #f9f9f9;
        }
      }
    }
  }

  .btn-area {
    padding: 0 1.7rem 1.9rem;
  }
}

@media (hover: hover) {
  .delete-btn:hover {
    background-color: #f9f9f9;
  }
}